import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';
import Cookies from 'js-cookie';
import Dashboard2 from './dash2';
const RestrictedDashboard = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [userRank, setUserRank] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/'); // Redirect to login if not authenticated
    } else {
      // Get user rank from cookies
      const cookieValue = Cookies.get('_auth_state');

    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["access"])  
    console.log("hi")
    setUserRank(authstate["access"]);
      if (authstate && authstate["access"] === "accounts") {
        navigate('/user_accounts'); // Redirect to userdetails/ if rank is greater than 5
      }
      
    }
  }, [isAuthenticated, navigate]);

  // Render null as this component doesn't have any UI
  return userRank && (userRank === "all" || userRank === "users") ? <Dashboard2 /> : null;
};

export default RestrictedDashboard;
