import React from "react";
import "./login.css";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from "../../Apis/network";
// import { GetProfile } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useSignIn } from 'react-auth-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

const Welcome = () => {

  const navigate = useNavigate();
  const showAlert = useSweetAlert();
  const signIn = useSignIn()
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
 const handleSubmit = async (event) => {
    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
      document.cookie = `${cookie.split("=")[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
    if (username && password) {
      let params = {
        'username': username,
        'password': password

      }
      console.log("params", params)
      LoginService(params)
        .then(async (res) => {
          console.log("res", res)

          if (res.status == 200) {
            // res = JSON.parse(res)
            console.log("in if success", res.data)
            var role_check;
            if (res.data.data.tenants === "1"){
              role_check = "all"
            }
            else if(res.data.data.users === "1"){
              role_check = "users"
            }
            else if(res.data.data.accounts === "1"){
              role_check = "accounts"
            }
            signIn({
              token: res.data.data.access_token,
              tokenType: "Bearer",
              accessTokenExpiry: res.data.data.access_token_expiry, // Set it here
              expiresIn: 60,
              authState: {
                id: res.data.data.id,
                username: res.data.data.username,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name,
                email: res.data.data.email,
                company: res.data.data.company,
                accessToken: res.data.data.access_token,
                accessTokenExpiry: res.data.data.access_token_expiry, // Set it here as well
                refreshToken: res.data.data.refresh_token,
                refreshTokenExpiry: res.data.data.refresh_token_expiry,
                role: res.data.data.role,
                rank: res.data.data.rank,
                access : role_check,


              }
            })
            setCookie("accessTokenExpiry", res.data.data.access_token_expiry)
            // const accessTokenExpiry = Cookies.get('accessTokenExpiry', res.data.data.access_token_expiry);
            showAlert('success', {
              title: 'Successfully logged in! Redirecting...',
            });
            var user_id = res.data.data.id;
            var currentPage = 1;
            var tenant_id = res.data.data.company
            await sleep(2000);
            // console.log("hello from login", id, page, tenant_id)
            if (role_check === "accounts" ){
                navigate('/user_accounts', { state: { user_id, tenant_id, currentPage } })
            }
            else {
              navigate('/dash');
            }
            
            //  }else{
            //  navigate('/addprofile');
            //  }
          }
          else {
            showAlert('warning', {
              title: 'Username or password is incorrect.',
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            showAlert('error', {
              title: err.response.data.message,
              timer: 3000,
            });
          } else {
            showAlert('error', {
              title: 'Something went wrong. Please try again.',
              timer: 3000,
            });
            console.log("err", err)
          }
        })
    }
  };

  function setCookie(name, value) {
    const date = new Date();
    date.setTime(date.getTime() + (3600000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }






  return (
    <div className="login-container">
      <div className="login-content">
        <h4>
          <b>Welcome!</b>
        </h4>
        <small>Connect, engage, and grow!</small>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username<span>*</span></label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Enter your username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password<span>*</span></label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
          </div>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit}
          >
            Login
          </button>
        </form>
        <div className="text-center">
          <a href="/forgot_password">Forgot Password?</a>
        </div>
        <div className="text-center">
          Don't have an Account? <a href="/started">Register here</a>
        </div>
      </div>
    </div>
  );
};

export default Welcome;