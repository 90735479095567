import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_accounts } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate } from "react-router-dom";
// import "./dash.css";
import Cookies from 'js-cookie';
import { get_users } from "../../Apis/network";
import { add_new_tenant } from "../../Apis/network";
import { refreshToken } from "../../Apis/network";
import { add_new_account } from "../../Apis/network";
import { add_new_user_account } from "../../Apis/network";
const CreateAccountWidget = () => {
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
    const [tenat, setTenant] = useState([]);
    const [usertenant, setUserTenant] = useState("")
    const [useroptions, setUseroptions] = useState([])
    const [createAccount, setCreateAccount] = useState({
        "userId": "",
        "accountType": "",
        "tenantId": "",
        "isExternal": "",
        "extBankName": "",
        "extBankAccount": "",
        "extBankAdditionalDetails": "",
        "extBankSortCode": "",
      })
      const handleChangeAccount = (e) => {
        const { name, value } = e.target;
        setCreateAccount((prevData) => ({ ...prevData, [name]: value }));
        if(name==="tenantId"){
          get_users(value)
              .then((res) => {
                if (res.status === 200) {
                  let data = res.data.data;
                  setUseroptions(data);
                }
              })
              .catch((err) => {
                console.error("Error fetching tenants:", err);
              });
        }
      };

      function AccountCreation(){
        add_new_account(createAccount)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setCreateAccount({
              email: "",
            postCode: "",
            address: "",
            phoneNo: "",
            companyName: "",
            })
    
            // closeAccountModal();
            showAlert('success', {
              title: "New account number is " + res.data.data.accountId,
              timer: 5000,
            });
          }
          else{
              console.log(" in error")
              showAlert('error', {
                  title: res.data.message,
                  timer: 3000,
                });
                
          }
        })
        .catch((err) => {
          console.error("Error fetching accounts:", err);
    
          showAlert('error', {
              title: err.response.data.message,
              timer: 3000,
            });
            
        });
    
      }
  const handleSubmitAccount = () => {
    if (createAccount.accountType && createAccount.userId){
      if (createAccount.accountType === "external"){
        createAccount.isExternal = 1
        if(createAccount.extBankAccount === "" && createAccount.extBankName=== "" && createAccount.extBankSortCode === ""){
          showAlert('error', {
            title: "Fill all required fileds",
            timer: 3000,
          });
        
        }
        else{
          AccountCreation()
        }

      }
      else{
        createAccount.isExternal = 0
        AccountCreation()
      }

    }else{
      showAlert('error', {
        title: "Fill all required fileds",
        timer: 3000,
      });
    }
    console.log("Account create", createAccount)
  }
  useEffect(() => {
    console.log("cookiessssss", document.cookie);
    const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["role"])
    if(authstate["role"] === "superuser"){
      setRole("superuser")
      
        // console.log("show div", showDiv)
        get_tenants()
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setTenant(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
    }
    else if(authstate["role"] === "system"){
      setUserTenant(authstate['company'])
      setRole("system")
        // getting_setting_accounts(authstate['company'])
        get_users(authstate['company'])
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setUseroptions(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
    }

  }, []);
return (
    <>

      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
    //   aria-hidden={!showAccountModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New Account
            </h5>
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeAccountModal}
            ></button> */}
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            {role === 'superuser' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={createAccount.tenantId}
              onChange={handleChangeAccount}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              User*:
            </label>
            <select
              className="form-control"
              id="userId"
              name="userId"
              value={createAccount.userId}
              onChange={handleChangeAccount}
              disabled={createAccount.role === 'superuser' || useroptions.length === 0}
            >
              <option value="">Select a user</option>
              {useroptions.map((user) => (
              useroptions.length > 0 && (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              )
            ))}
            </select>
          </div>
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Account Type*:
            </label>
            <select
              className="form-control"
              id="accountType"
              name="accountType"
              value={createAccount.accountType}
              onChange={handleChangeAccount}
            >
              <option value="">Select account type</option>
              <option value="normal">Normal</option>
              <option value="system">System</option>
              <option value="external">External</option>
            </select>
          
            </div>
            
            {createAccount.accountType === "external" && (
  <div>
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        External Bank Name*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankName"
        name="extBankName"
        value={createAccount.extBankName}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankAccount" className="form-label">
        External Bank Account*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankAccount"
        name="extBankAccount"
        value={createAccount.extBankAccount}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankAdditionalDetails" className="form-label">
        External Bank Additional Details:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankAdditionalDetails"
        name="extBankAdditionalDetails"
        value={createAccount.extBankAdditionalDetails}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankSortCode" className="form-label">
        External Bank Sort Code*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankSortCode"
        name="extBankSortCode"
        value={createAccount.extBankSortCode}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
  </div>
)}
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitAccount}
            >
              Create Account
            </button>
            {/* <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button> */}
          </div>
        </div>
      </div>
    </div>
   </>
   );
 };
 
 export default CreateAccountWidget;