import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_users, get_accounts } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';
import { get_account_transaction } from "../../Apis/network";
import { update_user } from "../../Apis/network";
import { approve_reject } from "../../Apis/network";
import { useLocation, useNavigate } from 'react-router-dom';

const ViewTransactionWidget = () => {
  const navigate = useNavigate();
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
  const [tenat, setTenant] = useState([]);
  const [userTenant, setUserTenant] = useState("");
  const [filters, setFilters] = useState({})
  const [transactions, setTransactions] = useState([])
  const [userdatastate, setUserdatastate] = useState({})
  const [Accept, setAccept] = useState({})
  const [approvalFilter, setApprovalfilter] = useState(false)
  const [validationFilter, setValidationfilter] = useState(false)
  const [webcall, setWebcall] = useState(false)
  const [debit_credit, setDebit_credit] = useState({
    "total_debit": 0,
    "total_credit": 0,
    "total_debit_pending": 0,
    "total_credit_pending": 0
  }) 
  
  
    
  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    setFilters((prevData) => ({ ...prevData, [name]: value }));
    
  };
  const getting_setting_transactions = (page) => {
    if (page !== 0){
        userdatastate.page = page
        
    }
    else{
        page = userdatastate.page
    }
        get_account_transaction(userdatastate)
        .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              // console.log("res", res)
              setPaginationData({
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous
            });
            setDebit_credit({
              "total_debit": res.data.total_debit,
              "total_credit": res.data.total_credit,
              "total_debit_pending": res.data.total_debit_pending,
              "total_credit_pending": res.data.total_credit_pending
            })
            setCurrentPage(page)
            // setTransactions([])  
            setTransactions(res.data.results)
              
            }
          })
          .catch((err) => {
            // console.error("Error fetching tenants:", err);
          });
  }

  const handleSubmit = () => {
    setTransactions([]);
    // Handle the submission logic here
    // console.log("Form submitted with the following data:");
    setCurrentPage(1)
    filters.page = 1
    // console.log("approval filter", approvalFilter)
    if(approvalFilter === true){
      filters.approval = 1
    }
    else{
      filters.approval = 0
    }
    if(validationFilter === true){
      filters.validation = 1
    }
    else{
      filters.validation = 0
    }
    if (role != "superuser"){
        filters.tenantId = userTenant
    }
    // console.log("filters", filters)
    setUserdatastate(filters)
    get_account_transaction(filters)
    .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          // console.log("res", res)
          setPaginationData({
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
        });
        setDebit_credit({
          "total_debit": res.data.total_debit,
          "total_credit": res.data.total_credit,
          "total_debit_pending": res.data.total_debit_pending,
          "total_credit_pending": res.data.total_credit_pending
        })
        
          setTransactions(res.data.results)
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
};
const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    count: 0,
    next: null,
    previous: null
});
  const goToNextPage = () => {
    // console.log("pagination data", paginationData)
    if (paginationData.next) {
      // console.log("pagination data", paginationData)
        const nextPage = currentPage + 1;
        getting_setting_transactions(nextPage);
    }
};
const goToPreviousPage = () => {
  if (paginationData.previous) {
      const prevPage = currentPage - 1;
      getting_setting_transactions(prevPage);
  }
};
const location = useLocation();
const [loginrequired, setLoginrequired] = useState(true)
    useEffect(() => {
        // console.log("hi")
        const cookieValue = Cookies.get('auth_state');
        if (cookieValue){
          
            setLoginrequired(false)
        }
        else{
          navigate("/login_transaction_widget")
        }
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
        
        const queryParams = new URLSearchParams(location.search);
        const data = queryParams.get('account_id');
        
        
          
        // console.log("filters2", data.status)
        if (authstate["role"] === "superuser") {
          setRole("superuser");
          get_tenants()
            .then((res) => {
              if (res.status === 200) {
                let data = res.data.data;
                setTenant(data);
              }
            })
            .catch((err) => {
              console.error("Error fetching tenants:", err);
            });
        } else if (authstate["role"] === "system") {
          setUserTenant(authstate['company']);
          setRole("system");
          
        //   get_users(authstate['company'])
        //     .then((res) => {
        //       if (res.status === 200) {
        //         let data = res.data.data;
        //         setUseroptions(data);
        //       }
        //     })
        //     .catch((err) => {
        //       console.error("Error fetching tenants:", err);
        //     });
        }
        
        if (queryParams.get('account_id')){
          filters.account_id = queryParams.get('account_id')
          // console.log("account", queryParams.get('account_id'))
        }
        if (queryParams.get('status')){
          filters.status = queryParams.get('status')
          // console.log("status", queryParams.get('status'))
        }
        if (queryParams.get('datefrom')){
          filters.datefrom = queryParams.get('datefrom')
          // console.log("datefrom", queryParams.get('datefrom'))
        }
        if (queryParams.get('datetill')){
          filters.datetill = queryParams.get('datetill')
          // console.log("datetill", queryParams.get('datetill'))
        }
        if (queryParams.get('datefrom_created')){
          filters.datefrom_created = queryParams.get('datefrom_created')
          // console.log("datefromcreated", queryParams.get('datefrom_created'))
        }
        if (queryParams.get('datetill_created')){
          filters.datetill_created = queryParams.get('datetill_created')
          // console.log("datetillcreated", queryParams.get('datetill_created'))
        }
        if (queryParams.get('approval')){
          filters.approval = queryParams.get('approval')
          // console.log("approval", queryParams.get('approval'))
        }
        if (queryParams.get('validation')){
          filters.validation = queryParams.get('validation')
          // console.log("validation", queryParams.get('validation'))
        }
        if (queryParams.get('web_call')){
          setWebcall(true)
          // filters.page = 1
          filters.tenantId = authstate['company']
          get_account_transaction(filters)
        .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              // console.log("res", res)
              setPaginationData({
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous
            });
            setDebit_credit({
              "total_debit": res.data.total_debit,
              "total_credit": res.data.total_credit,
              "total_debit_pending": res.data.total_debit_pending,
              "total_credit_pending": res.data.total_credit_pending
            })
            // setCurrentPage(page)
            // setTransactions([])  
            setTransactions(res.data.results)
            setUserdatastate(filters)
              
            }
          })
          .catch((err) => {
            console.error("Error fetching tenants:", err);
          });
          // console.log("webcall", queryParams.get('web_call'))
        }
      }, []);
      const handleApproval1Change = (id,e) => {
        // console.log("approval 1", id, e.target.value)
        setAccept({
            "transactionId": id,
            "approval1":  e.target.value,
            "comments": ""
        })

        openModal()
      }
      const handleApproval2Change = (id,e) => {
        // console.log("approval 2", id, e.target.value)
        setAccept({
            "transactionId": id,
            "approval2":  e.target.value,
            "comments": ""
        })
        openModal()
      }
      const [showModal, setShowModal] = useState(false);
      const openModal = () => {
        setShowModal(true);
      };
      const closeModal = () => {
        // getting_setting_transactions(0)
        setAccept({
            "transactionId": "",
            "approval2": "",
            "comments": ""
        })
        setShowModal(false);

    }
    const handleChangeComments = (e) => {
        // console.log("value", Accept)
        setAccept(prevAccept => ({
            ...prevAccept,
            comments: e.target.value
        }));
    }
    const handleSubmitApproval = (e) => {
        // console.log("value123", Accept)
        if (Accept.comments !== ""){
            approve_reject(Accept)
            .then((res) => {
                if (res.status === 200) {
                //   let data = res.data.data;
                //   setTenant(data);
                showAlert('success', {
                    title: res.data.message,
                  });
                closeModal()
                getting_setting_transactions(0)
                }
              })
              .catch((err) => {
                // console.error("Error fetching tenants:", err);
                if (err.response.data){
                showAlert('error', {
                    title: err.response.data.message,
                  });}
                  else{
                    showAlert('error', {
                        title:"Something went wrong.",
                      });
                  }

              });

        }
    }
    const handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      // console.log("hgdjas", name, checked)
      if (name === "approval") {
        // console.log("in if", name, checked)
          setApprovalfilter(checked);
      } else if (name === "validation") {
          setValidationfilter(checked);
      }
  };
    return (
        <>
        <div className="m-5">
        <div className="mb-3" style={{ display: loginrequired ? 'block' : 'none' }}>
            <h5>
                Please Login first.
            </h5>
        </div>
        {role === 'superuser' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={filters.tenantId}
              onChange={handleChangeInputs}
              disabled={webcall}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
          
            
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Account No:
            </label>
            <input
              className="form-control"
              id="account_id"
              name="account_id"
              value={filters.account_id}
              onChange={handleChangeInputs}
              disabled={webcall}
             
            />
              
            
          </div>
          <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Filters:
            </label>
            <div className="row mb-3">
            <div className="col-2">
            <input type="checkbox" name="approval" id="approval" checked={approvalFilter} onChange={handleCheckboxChange} disabled={webcall}/> Approval Required
              </div>
              <div className="col">
              <input type="checkbox" name="validation" id="validation" checked={validationFilter} onChange={handleCheckboxChange} disabled={webcall}/> Validation Required
              </div>
            </div>
            {/* <select
              className="form-control"
              id="type"
              name="type"
              value={filters.type}
              onChange={handleChangeInputs}
              multiple
            >
                <option value="">Select a filter</option>
            <option value="approval">
                  Approval Required
                </option>
                <option value="validation">
                  Validation Required
                </option>

            </select> */}
            <label htmlFor="accountType" className="form-label">
              Status:
            </label>
            <select
              className="form-control"
              id="status"
              name="status"
              value={filters.status}
              onChange={handleChangeInputs}
              disabled={webcall}
            >
                <option value="">Select status</option>
            <option value="pending">
                  Pending
                </option>
                <option value="completed">
                  Completed
                </option>
                <option value="rejected">
                  Rejected
                </option>

            </select>
              <div className="row mb-3">
                <div className="col-4">
                <label htmlFor="accountType" className="form-label">
              Date from:
            </label>
            <input
            type="date"
              className="form-control"
              id="datefrom"
              name="datefrom"
              value={filters.datefrom}
              onChange={handleChangeInputs}
              disabled={webcall}
            />


                </div>
                <div className="col-4">
                <label htmlFor="accountType" className="form-label">
              Date to:
            </label>
            <input
            type="date"
              className="form-control"
              id="datetill"
              name="datetill"
              value={filters.datetill}
              onChange={handleChangeInputs}
              disabled={webcall}
            />


                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">
                <label htmlFor="accountType" className="form-label">
              Date from Created:
            </label>
            <input
            type="date"
              className="form-control"
              id="datefrom"
              name="datefrom_created"
              value={filters.datefrom_created}
              onChange={handleChangeInputs}
              disabled={webcall}
            />


                </div>
                <div className="col-4">
                <label htmlFor="accountType" className="form-label">
              Date to Created:
            </label>
            <input
            type="date"
              className="form-control"
              id="datetill_created"
              name="datetill_created"
              value={filters.datetill_created}
              onChange={handleChangeInputs}
              disabled={webcall}
            />


                </div>
              </div>
              <button className="btn btn-success mb-3" onClick={handleSubmit} disabled={webcall}>Search</button>
            
          </div>

          {transactions.length > 0 ? (
  <div>
    <h2>Transaction History</h2>
    <div className="table-container">
    <table className="table">
      <thead>
        <tr>
          <th>From Account</th>
          <th>To Account</th>
          <th>Description</th>
          <th>status</th>
          <th>Amount</th>
          <th>Type</th>
          <th>ParentTransaction</th>
          <th>ChildTransaction</th>
          <th>Due Date</th>
          <th>Approval 1</th>
          <th>Approved/Reject by</th>
          <th>Approval 2</th>
          <th>Approved/Reject by</th>
          <th>initiated By</th>
          <th>completedOn</th>
          <th>Transaction Start</th>

          {/* Add more headers based on your transaction object keys */}
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction) => (
          <tr key={transaction.transactionId}>
            <td>{transaction.fromAccount !== null && transaction.fromAccount !== '' ? transaction.fromAccount : 'null'}</td>
            <td>{transaction.toAccount !== null && transaction.toAccount !== '' ? transaction.toAccount : 'null'}</td>
            <td>{transaction.description !== null && transaction.description !== '' ? transaction.description : 'null'}</td>
            <td>{transaction.status !== null && transaction.status !== '' ? transaction.status : 'null'}</td>
            <td>{transaction.transactionAmount !== null && transaction.transactionAmount !== '' ? transaction.transactionAmount : 'null'}</td>
            <td>{transaction.transactionType !== null && transaction.transactionType !== '' ? transaction.transactionType : 'null'}</td>
            <td>{transaction.parentTransaction !== null && transaction.parentTransaction !== '' ? transaction.parentTransaction : 'null'}</td>
            <td>{transaction.childTransaction !== null && transaction.childTransaction !== '' ? transaction.childTransaction : 'null'}</td>
            <td>{transaction.transactionDueDate !== null && transaction.transactionDueDate !== '' ? transaction.transactionDueDate : 'null'}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
    {
        (transaction.role_for_approval1 === role) ? (
            transaction.approval1 === 'approval_required' ? (
                <div>
                    <button className="btn btn-success" name={`approval_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval1Change(transaction.id, e)}>Approve</button>
                    <button className="btn btn-danger" name={`approval_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval1Change(transaction.id, e)}>Not Approved</button>
                </div>
            ) : transaction.approval1 === 'validation_required' ? (
                <div>
                    <button className="btn btn-success" name={`validation_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval1Change(transaction.id, e)}>Validate</button>
                    <button className="btn btn-danger" name={`validation_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval1Change(transaction.id, e)}>Reject</button>
                </div>
            ) : (
                transaction.approval1 !== null && transaction.approval1 !== '' ? transaction.approval1 : 'null'
            )
        ) : (
            transaction.approval1 !== null && transaction.approval1 !== '' ? transaction.approval1 : 'null'
        )
    }
</td>
<td>{transaction.approval1by !== null && transaction.approval1by !== '' ? transaction.approval1by : 'null'}</td>
<td style={{ whiteSpace: 'nowrap' }}>
    {
        (transaction.role_for_approval2 === role) ? (
            transaction.approval2 === 'approval_required' ? (
                <div>
                    <button className="btn btn-success" name={`approval_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval2Change(transaction.id, e)}>Approve</button>
                    <button className="btn btn-danger" name={`approval_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval2Change(transaction.id, e)}>Not Approved</button>
                </div>
            ) : transaction.approval2 === 'validation_required' ? (
                <div>
                    <button className="btn btn-success" name={`validation_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval2Change(transaction.id, e)}>Validate</button>
                    <button className="btn btn-danger" name={`validation_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval2Change(transaction.id, e)}>Reject</button>
                </div>
            ) : (
                transaction.approval2 !== null && transaction.approval2 !== '' ? transaction.approval2 : 'null'
            )
        ) : (
            transaction.approval2 !== null && transaction.approval2 !== '' ? transaction.approval2 : 'null'
        )
    }
</td>
<td>{transaction.approval2by !== null && transaction.approval2by !== '' ? transaction.approval2by : 'null'}</td>
            <td>{transaction.initiatedBy !== null && transaction.initiatedBy !== '' ? transaction.initiatedBy : 'null'}</td>
            <td>{transaction.completedOn !== null && transaction.completedOn !== '' ? transaction.completedOn : 'null'}</td>
            <td>{transaction.created_at !== null && transaction.created_at !== '' ? transaction.created_at : 'null'}</td>
            {/* Add more cells based on your transaction object keys */}
          </tr>
        ))}
      </tbody>
    </table>
    <div className="row">
    {transactions.length !== 0 && (
  <div className="pagination-container" style={{display: "flex", justifyContent: "space-between"}}>
    <div className="pagination-controls col-4">
      <button className="btn btn-success" onClick={goToPreviousPage} disabled={!paginationData.previous}>
        Previous
      </button>
      <span style={{ margin: "5px" }}>{currentPage}</span>
      <button className="btn btn-success" onClick={goToNextPage} disabled={!paginationData.next}>
        Next
      </button>
    </div>

    <div className="col-3" style={{margin: "5px" }}>
      {filters.account_id && (
        <div>
          <label><h5>Debit:</h5></label>
          <span style={{margin: "2px"}}>{debit_credit.total_debit}</span>
        </div>
      )}
    </div>

    <div className="col-3" style={{margin: "5px" }}>
      {filters.account_id && (
        <div>
          <label><h5>Credit:</h5></label>
          <span style={{margin: "2px"}}>{debit_credit.total_credit}</span>
        </div>
      )}
    </div>

    <div className="col-3" style={{margin: "5px" }}>
      {filters.account_id  && (
        <div>
          <label><h5>Debit Pending:</h5></label>
          <span style={{margin: "2px"}}>{debit_credit.total_debit_pending}</span>
        </div>
      )}
    </div>

    <div className="col-3" style={{margin: "5px" }}>
      {filters.account_id && (
        <div>
          <label><h5>Credit Pending:</h5></label>
          <span style={{margin: "2px"}}>{debit_credit.total_credit_pending}</span>
        </div>
      )}
    </div>
  </div>
)}
    </div>
    
  </div>
  </div>
  
) : (
  <div>
    <h2>No Transactions Found</h2>
  </div>
)}
          {showModal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add Comments
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
          <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Comments:
              </label>
              <input
                type="text"
                className="form-control"
                id="comments"
                name="comments"
                value={Accept.comments}
                onChange={handleChangeComments}
                maxlength="2000"
              />
            </div>
            
           </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitApproval}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
        </div>
        
         </>
  );
  };

export default ViewTransactionWidget;