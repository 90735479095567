import React from "react";
import "./login_widget.css";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from "../../Apis/network";
// import { GetProfile } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import {useSignIn} from 'react-auth-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

const LoginWidget = () => {
    const currentDomain = window.location.host;
    console.log("current", currentDomain)
  const navigate = useNavigate();
  const showAlert = useSweetAlert();
  const signIn = useSignIn()
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
    function getCookiesForDomain(domain) {
        return document.cookie
          .split(';')
          .map(cookie => cookie.trim())
          .filter(cookie => cookie.startsWith(`${domain}=`))
          .map(cookie => cookie.split('=')[1]);
      }
    const handleSubmit = (event) => {
        // debugger
        
// console.log("update cookies", cookiesForLocalhost);
      if(username && password){
        let params = {
            'username': username,
            'password': password
    
    }
    console.log("params", params)
    
        LoginService(params)
    .then((res) => {
      console.log("res", res)
      
      if(res.status == 200){
        // res = JSON.parse(res)
          console.log("in if success",res.data)
          
          // signIn({
          //   token = res.data.data.access_token,
          //   tokenType = ,
          //   accessTokenExpiry = res.data.data.access_token_expiry, // Set it here
          //   expiresIn = 3600,
           const auth_state = {
              username: res.data.data.username,
              first_name: res.data.data.first_name,
              last_name: res.data.data.last_name,
              email: res.data.data.email,
              company: res.data.data.company,
              accessToken: res.data.data.access_token,
              accessTokenExpiry: res.data.data.access_token_expiry, // Set it here as well
              refreshToken: res.data.data.refresh_token,
              refreshTokenExpiry: res.data.data.refresh_token_expiry,
              role: res.data.data.role
            }
          
          console.log("token", res.data.data.access_token)
          setCrossDomainCookie("auth1", res.data.data.access_token)
          setCrossDomainCookie("auth_type", "Bearer")
          setCrossDomainCookie("auth_state", JSON.stringify(auth_state))
          // setCrossDomainCookie("accessTokenExpiry", res.data.data.access_token_expiry)
          setCrossDomainCookie("accessTokenExpiry", res.data.data.access_token_expiry)
          console.log("cookies set", Cookies.get("accessTokenExpiry"))
          // const accessTokenExpiry = Cookies.get('accessTokenExpiry', res.data.data.access_token_expiry);
        showAlert('success', {
          title: 'Successfully logged in.',
        });
      }
    //  navigate('/dash');
    //  }else{
    //  navigate('/addprofile');
    //  }
    
    else{
      showAlert('warning', {
        title: 'Username or password is incorrect.',
      });
    }
    })
    .catch((err) => {
        if (err.response && err.response.data) {
            showAlert('error', {
              title: err.response.data.message,
              timer: 3000,
            });
          } else {
            showAlert('error', {
              title: 'Something went wrong. Please try again.',
              timer: 3000,
            });
            console.log("err", err)
          }
    })
  }
};

function setCrossDomainCookie(name, value, hours = 1) {
  // Set the expiration time in milliseconds
  const expirationTime = hours * 60 * 60 * 1000;

  // Create a new Date object and set the time to the current time plus the expiration time
  const date = new Date();
  date.setTime(date.getTime() + expirationTime);

  // Convert the date to a UTC string for the 'expires' attribute
  const expires = "expires=" + date.toUTCString();

  // Set the cookie with appropriate attributes
  // Ensure SameSite=None and Secure only for secure connections (HTTPS)
  document.cookie = name + "=" + value + ";" + expires + ";path=/;SameSite=None;Secure";
}

  


  

  

return (
  <div className="login-container">
    <div className="login-content">
      <h4>
        <b>Welcome!</b>
      </h4>
      <small>Connect, engage, and grow!</small>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username<span>*</span></label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Enter your username"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password<span>*</span></label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter your password"
          />
        </div>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
        >
          Login
        </button>
      </form>
      
      <div className="text-center">
        Don't have an Account? <a href="/started">Register here</a>
      </div>
    </div>
  </div>
);
};

export default LoginWidget;