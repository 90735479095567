import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_users } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';

const ViewTenantWidget = () => {
  const showAlert = useSweetAlert();
  const [role, setRole] = useState("");
  const [tenat, setTenant] = useState([]);
  const [userTenant, setUserTenant] = useState("");
  const [useroptions, setUseroptions] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");

  useEffect(() => {
    const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};

    if (authstate["role"] === "superuser") {
      setRole("superuser");
      get_tenants()
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setTenant(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
    } 
    
  }, []);

  const handleChangeAccount = (e) => {
    setSelectedTenant(e.target.value);
    get_users(e.target.value)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setUseroptions(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
  };

  // ... (your existing code)

return (
    <>
      <div
        className={`modal show`}
        style={{ display: "block" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addTenantModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTenantModalLabel">
                Tenants
              </h5>
            </div>
            <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {/* Your existing content */}
              
  
              {tenat.length > 0 && (
                <div>
                  <table className="table">
                  <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tenat.map((user) => (
                        <tr key={user.id}>
                          <td>{user.companyName}</td>
                          <td>{user.email}</td>
                          <td>{user.phoneNo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  };

export default ViewTenantWidget;
