import React, { useState, useEffect } from 'react';
import { check_reset_link } from '../../Apis/network';
import { useParams, useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import useSweetAlert from "../../Alerts/useSweetAlert";
import { reset_password } from '../../Apis/network';
const PasswordResetCheck = ({ match }) => {
  const [isValidLink, setIsValidLink] = useState(null);
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const { uidb64, token } = useParams();
  const showAlert = useSweetAlert();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(uidb64, token);
    // const { uidb64, token } = match.params;
    // const apiUrl = `https://your-api-url/password-reset-check/${uidb64}/${token}/`;

    // fetch(apiUrl)
    check_reset_link(uidb64, token)
    .then((res) => {
        if(res.status === 200){
        if (res.data.detail  === 'Password reset link is valid.'){
            setIsValidLink(true)
        }
    }
        // setIsValidLink(data.detail === 'Password reset link is valid');
      })
      .catch((error) => {
        setIsValidLink(false);
        console.error('Error:', error);
      });
  }, []);

  const handleChangePassword = (e) => {
        setPassword(e.target.value)
  }
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }
  const handleSubmit = () => {
    if(password !== "" && confirmPassword !== ""){
        console.log("pass", password)
        console.log("pass1", confirmPassword)
        if(password === confirmPassword){
            let params = {
                "password": password,
                "confirmpassword": confirmPassword
            }
            reset_password(uidb64, token, params)
        .then((res) => {
            console.log("res", res)
            if(res.status === 200){
                
            if (res.data.message  === 'Password reset successfully.'){
                showAlert('success', {
                    title: 'Password reset.',
                  });
                  
            }
            setTimeout(() => {
                navigate('/');
              }, 3000);
            // navigate('/');
        }
        // setIsValidLink(data.detail === 'Password reset link is valid');
      })
      .catch((error) => {
        // setIsValidLink(false);
        console.error('Error:', error);
        showAlert('error', {
            title: error.response.data.message,
          });
      });
        }
        else{
            showAlert('warning', {
                title: 'Both Passwords donot match.',
              });
        }

    }
    else{
        showAlert('warning', {
            title: 'Both fields are required.',
          });
    }
  }

  return (
    <>
    <div className="login-container">
        
        {isValidLink === null && <p>Loading...</p>}
      {isValidLink === true && (
        <div className='login-content'>
        <div className="form-group">
        <h2>Reset Password</h2>
        <label htmlFor="username">New Password<span>*</span></label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={handleChangePassword}
          placeholder="Password"
        />
      </div>
      <div className="form-group">
        <label htmlFor="username">Confirm Password<span>*</span></label>
        <input
          type="password"
          className="form-control"
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          placeholder="Confirm Password"
        />
        <button
          type="button"
          className="btn btn-success mt-3"
          onClick={handleSubmit}
        >
          Reset Password
        </button>
      </div>
      </div>
        
      )}
      {isValidLink === false && <p>Invalid or expired reset link.</p>}
            </div>
            
    
    </>
  );
};

export default PasswordResetCheck;
