import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_account_details, get_account_transaction } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import "./account_details.css";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Apis/network2";
import { approve_reject } from "../../Apis/network";

const AccountDetails = () => {
  const showAlert = useSweetAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [Accept, setAccept] = useState({})
  const [account, setAccount] = useState({
    "account_id": -1,
    "tenant_id": "",
    "account": {}
    
  });
  const redirect_at = "/user_account"

  const [accountDetails, setAccountDetails] = useState({});
  const [transactions, setTransactions] = useState([]);
function getting_setting_transactions(page){
  if (page !== 0){
    setCurrentPage(page)
    
}
else{
  
}
  var token = Cookies.get('_auth');
        axiosInstance.get("accounts/get_transaction?page="+ currentPage +"&account_id=" + account.account_id + "&tenantId=" + account.tenant_id, {
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.results;
            setTransactions(data);
            setPaginationData({
              count: res.data.count,
              next: res.data.next,
              previous: res.data.previous
          });
          setCurrentPage(page);
          } else {
            showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
            setTransactions([]);
          }
        })
        .catch((err) => {
          console.error("Error fetching account transactions:", err);
          showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          setTransactions([]);
        });
}

  useEffect(() => {
    setAccount(location.state);
    console.log("location", location.state)
    const cookieValue = Cookies.get('_auth_state');
    if (!cookieValue){
      navigate("/")
    }
    if (account.account_id !== -1) {
      const authstate = cookieValue ? JSON.parse(cookieValue) : {};
      var token = Cookies.get('_auth');
      setRole(authstate["role"])
        axiosInstance.get('accounts/single_account_details?account_id=' + account.account_id ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setAccountDetails(data);
          } else {
            showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
            setAccountDetails({});
          }
        })
        .catch((err) => {
          console.error("Error fetching account details:", err);
          showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          setAccountDetails({});
        });
        getting_setting_transactions(1)

      
    }
    
  }, [account]);
  // useEffect(() => {
  //   console.log("in c components")
  //   const handlePopstate = () => {
  //     console.log("in c components")
  //     console.log("Navigating back to ComponentB from ComponentC");
  //     navigate("/user_account", { state: { account, redirect_at  } });
  //   };

  //   window.addEventListener('popstate', handlePopstate);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //     console.log("removing c")
  //   };
  // }, []);
  // window.onpopstate = () => {
  //   navigate("/user_account", { state: { account }});
  // }
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    count: 0,
    next: null,
    previous: null
});
  const goToNextPage = () => {
    console.log("pagination data", paginationData)
    if (paginationData.next) {
      console.log("pagination data", paginationData)
        const nextPage = currentPage + 1;
        getting_setting_transactions(nextPage);
    }
};
const goToPreviousPage = () => {
  if (paginationData.previous) {
      const prevPage = currentPage - 1;
      getting_setting_transactions(prevPage);
  }
};
const handleApproval2Change = (id,e) => {
  console.log("approval 2", id, e.target.value)
  setAccept({
      "transactionId": id,
      "approval2":  e.target.value,
      "comments": ""
  })
  openModal()
}
const [showModal, setShowModal] = useState(false);
const openModal = () => {
  setShowModal(true);
};
const closeModal = () => {
  // getting_setting_transactions(0)
  setAccept({
      "transactionId": "",
      "approval2": "",
      "comments": ""
  })
  setShowModal(false);

}
const handleApproval1Change = (id,e) => {
  console.log("approval 1", id, e.target.value)
  setAccept({
      "transactionId": id,
      "approval1":  e.target.value,
      "comments": ""
  })

  openModal()
}
const handleChangeComments = (e) => {
  console.log("value", Accept)
  setAccept(prevAccept => ({
      ...prevAccept,
      comments: e.target.value
  }));
}
const handleSubmitApproval = (e) => {
  console.log("value123", Accept)
  var token = Cookies.get('_auth');
  if (Accept.comments !== ""){
    axiosInstance.post('accounts/approve_reject/', Accept ,{
      headers:{
        'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
          if (res.status === 200) {
          //   let data = res.data.data;
          //   setTenant(data);
          showAlert('success', {
              title: res.data.message,
            });
          closeModal()
          getting_setting_transactions(0)
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
          if (err.response.data){
          showAlert('error', {
              title: err.response.data.message,
            });}
            else{
              showAlert('error', {
                  title:"Something went wrong.",
                });
            }

        });

  }
}
const reverse_transaction = (id) => {
  console.log("iddddddd", id)
 setReverseId(id)
 openreversemodal()
}
const [reverseConfirmation, setReverseConfirmation] = useState(false)
const [reverseId, setReverseId] = useState("")
const openreversemodal = () => {
  setReverseConfirmation(true)
}
const closereversemodal = () => {
  setReverseConfirmation(false)
  setReverseId("")
}
const submitReverse = () => {
  console.log("reverse id", reverseId)
  if (reverseId !== ""){
    var token = Cookies.get('_auth');
    var params = {
      "transaction_id": reverseId
    }
    axiosInstance.post('accounts/reverse_transaction/', params ,{
      headers:{
        'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
          if (res.status === 200) {
          //   let data = res.data.data;
          //   setTenant(data);
          showAlert('success', {
              title: res.data.message,
            });
          closereversemodal()
          getting_setting_transactions(currentPage)
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
          if (err.response.data){
          showAlert('error', {
              title: err.response.data.message,
            });}
            else{
              showAlert('error', {
                  title:"Something went wrong.",
                });
            }
  
        });
  }
}
  return (
    <>
      <Layout />
      <div className="container" style={{ marginTop: "80px" }}>
        

        {/* Display Account Details */}
        <div>
          <h2>Account Details</h2>
          <table className="table">
            <tbody>
              {Object.entries(accountDetails).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value !== null && value !== '' ? value : 'null'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Display Transactions */}
       

{/* Display Transactions */}


{/* Display Transactions */}
{transactions.length > 0 ? (
  <div>
    <h2>Transaction History</h2>
    <div className="table-container">
    <table className="table">
      <thead>
        <tr>
          <th>From Account</th>
          <th>To Account</th>
          <th>Description</th>
          <th>status</th>
          <th>Amount</th>
          <th>Type</th>
          <th>ParentTransaction</th>
          <th>ChildTransaction</th>
          <th>Due Date</th>
          <th>Approval 1</th>
          <th>Approval 2</th>
          <th>initiated By</th>
          <th>completedOn</th>
          <th>Transaction Start</th>
          <th>Reverse Transaction</th>

          {/* Add more headers based on your transaction object keys */}
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction) => (
          <tr key={transaction.transactionId}>
            <td>{transaction.fromAccount !== null && transaction.fromAccount !== '' ? transaction.fromAccount : 'null'}</td>
            <td>{transaction.toAccount !== null && transaction.toAccount !== '' ? transaction.toAccount : 'null'}</td>
            <td>{transaction.description !== null && transaction.description !== '' ? transaction.description : 'null'}</td>
            <td>{transaction.status !== null && transaction.status !== '' ? transaction.status : 'null'}</td>
            <td>{transaction.transactionAmount !== null && transaction.transactionAmount !== '' ? transaction.transactionAmount : 'null'}</td>
            <td>{transaction.transactionType !== null && transaction.transactionType !== '' ? transaction.transactionType : 'null'}</td>
            <td>{transaction.parentTransaction !== null && transaction.parentTransaction !== '' ? transaction.parentTransaction : 'null'}</td>
            <td>{transaction.childTransaction !== null && transaction.childTransaction !== '' ? transaction.childTransaction : 'null'}</td>
            <td>{transaction.transactionDueDate !== null && transaction.transactionDueDate !== '' ? transaction.transactionDueDate : 'null'}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
    {
        (transaction.role_for_approval1 === role) ? (
            transaction.approval1 === 'approval_required' ? (
                <div>
                    <button className="btn btn-success" name={`approval_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval1Change(transaction.id, e)}>Approve</button>
                    <button className="btn btn-danger" name={`approval_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval1Change(transaction.id, e)}>Not Approved</button>
                </div>
            ) : transaction.approval1 === 'validation_required' ? (
                <div>
                    <button className="btn btn-success" name={`validation_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval1Change(transaction.id, e)}>Validate</button>
                    <button className="btn btn-danger" name={`validation_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval1Change(transaction.id, e)}>Reject</button>
                </div>
            ) : (
                transaction.approval1 !== null && transaction.approval1 !== '' ? transaction.approval1 : 'null'
            )
        ) : (
            transaction.approval1 !== null && transaction.approval1 !== '' ? transaction.approval1 : 'null'
        )
    }
</td>

<td style={{ whiteSpace: 'nowrap' }}>
    {
        (transaction.role_for_approval2 === role) ? (
            transaction.approval2 === 'approval_required' ? (
                <div>
                    <button className="btn btn-success" name={`approval_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval2Change(transaction.id, e)}>Approve</button>
                    <button className="btn btn-danger" name={`approval_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval2Change(transaction.id, e)}>Not Approved</button>
                </div>
            ) : transaction.approval2 === 'validation_required' ? (
                <div>
                    <button className="btn btn-success" name={`validation_${transaction.transactionId}`} value="1" onClick={(e) => handleApproval2Change(transaction.id, e)}>Validate</button>
                    <button className="btn btn-danger" name={`validation_${transaction.transactionId}`} value="0" onClick={(e) => handleApproval2Change(transaction.id, e)}>Reject</button>
                </div>
            ) : (
                transaction.approval2 !== null && transaction.approval2 !== '' ? transaction.approval2 : 'null'
            )
        ) : (
            transaction.approval2 !== null && transaction.approval2 !== '' ? transaction.approval2 : 'null'
        )
    }
</td>
            <td>{transaction.initiatedBy !== null && transaction.initiatedBy !== '' ? transaction.initiatedBy : 'null'}</td>
            <td>{transaction.completedOn !== null && transaction.completedOn !== '' ? transaction.completedOn : 'null'}</td>
            <td>{transaction.created_at !== null && transaction.created_at !== '' ? transaction.created_at : 'null'}</td>
            <td>{transaction.status === "completed" ? <button className="btn btn-danger" onClick={() => reverse_transaction(transaction.id)}>Reverse</button> : 'N/A'}</td>
            {/* Add more cells based on your transaction object keys */}
          </tr>
        ))}
      </tbody>
    </table>
    {transactions.length !== 0 && (<div className="pagination-controls">
            <button className="btn btn-success" onClick={goToPreviousPage} disabled={!paginationData.previous}>Previous</button>
            <span style={{margin: "5px"}}>{currentPage}</span>
            <button className="btn btn-success" onClick={goToNextPage} disabled={!paginationData.next}>Next</button>
        </div>)}
    </div>
  </div>
) : (
  <div>
    <h2>No Transactions Found</h2>
  </div>
)}

{showModal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add Comments
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
          <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Comments:
              </label>
              <input
                type="text"
                className="form-control"
                id="comments"
                name="comments"
                value={Accept.comments}
                onChange={handleChangeComments}
                maxlength="2000"
              />
            </div>
            
           </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitApproval}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    {reverseConfirmation && (
                    <div
                        className={`modal show`}
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="addTenantModalLabel"
                        aria-hidden={!reverseConfirmation}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addTenantModalLabel">
                                        Reverse Confirmation
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closereversemodal}
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                                    
                                    {/* Form fields for new tenant */}
                                    <div className="mb-3">
                                        Are you sure? You want to reverse this transaction?</div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={submitReverse}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={closereversemodal}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>)}


      </div>
    </>
  );
};

export default AccountDetails;
