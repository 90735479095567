import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_account_details, get_account_transaction } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";

import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Apis/network2";


const PeriodicTransactions = () => {
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
    const [usertenant, setUserTenant] = useState("");
    const [accesstype, setAccessType] = useState(null);
    const [tenat, setTenant] = useState([]);
    const [showDiv, setShowdiv] = useState(false)
    const [selected_tenant, setSelectedTenant] = useState('');
    const [getperiodicdata, setperiodicData] = useState([])
    const [tenantfortask, setTenantfortask] = useState("")
    const [userGroup, setUserGroup] = useState([])
    const [sendercredentials, setSendercredentials] = useState({
        "id": "",
        "userId": ""
    })
    const [receivercredentials, setReceivercredentials] = useState({
        "id": "",
        "userId": ""
    })
    const [newtask, setNewTask] = useState({
        "fromAccount": "",
        "toAccount": "",
        "periodic_days": "",
        "periodic_day_month": "",
        "transaction_amount": "",
        "description": "",
        "tenantId": "",
        "approvalRequired1": "not_required",
        "approvalRequired2": "not_required",
        "group1": null,
        "group2": null,

    })
    const [openperiodicmodal, setOpenperiodicmodal] = useState(false)
    const [showdays, setDays] = useState(true)
    const [showdaysofmonth, setDaysOfMonth] = useState(true)
    const closeModaltask = () => {
        setOpenperiodicmodal(false)
        setSenderaccount("")
        setReceiveraccount("")

        setSendercredentials({
            "id": "",
            "userId": ""
        })
        setReceivercredentials({
            "id": "",
            "userId": ""
        })
        setNewTask({
            "fromAccount": "",
            "toAccount": "",
            "periodic_days": "",
            "periodic_day_month": "",
            "transaction_amount": "",
            "description": "",
            "tenantId": "",
            "approvalRequired1": "not_required",
            "approvalRequired2": "not_required",
            "group1": null,
            "group2": null,


        })
        setDaysOfMonth(true)
        setDays(true)
    }
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const openDeleteConfirmation = () => {
        setDeleteConfirmation(true)
    }
    const closeDeleteConfirmation = () => {
        setDeleteConfirmation(false)
        setDeleteId(null)
    }
    const openModaltask = () => {
        setOpenperiodicmodal(true)
    }
    const handleTenantChangeTask = (event) => {
        setTenantfortask(event.target.value)
        GetUserGroups(event.target.value)
    }
    const handleTenantChange = (event) => {
        setSelectedTenant(event.target.value);
        if (event.target.value !== "") {
            getTaskData(event.target.value)

            // localStorage.setItem("tenant", event.target.value)
            // getting_setting_accounts(event.target.value, 1)
        }
    }
    const handleSubmitTask = (e) => {
        console.log("new task", newtask)
        if (sendercredentials.id !== "" && receivercredentials.id !== "" && newtask.description !== "" && newtask.transaction_amount !== "" && (newtask.periodic_day_month !== "" || newtask.periodic_days !== "")) {
            newtask.fromAccount = sendercredentials.id
            newtask.toAccount = receivercredentials.id
            if (tenantfortask !== "") {
                newtask.tenantId = tenantfortask

                var token = Cookies.get('_auth');
                axiosInstance.post('accounts/periodic_tasks/', newtask, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then((res) => {
                        if (res.status === 200) {
                            let data = res.data.data;
                            console.log("data", res)
                            showAlert('success', {
                                title: res.data.message,
                                timer: 3000,
                            });

                            closeModaltask()
                            getTaskData(selected_tenant)
                        }
                        else {
                            showAlert('error', {
                                title: res.data.message,
                                timer: 3000,
                            });
                        }
                    })
                    .catch((err) => {
                        console.error("Error fetching tenants:", err);
                        showAlert('error', {
                            title: err.response.data.message,
                            timer: 3000,
                        });
                    });
            }
            else {
                showAlert('warning', {
                    title: "Please fill required fields.",
                    timer: 3000,
                });
            }
        }
        else {
            showAlert('warning', {
                title: "Please fill required fields. And get title for accounts.",
                timer: 3000,
            });
        }
    }
    const handleChangenewtask = (e) => {
        console.log("in change")
        
        var { name, value } = e.target;
        if (name === "periodic_days" && value !== "") {
            setDaysOfMonth(false)
        }
        else if (name === "periodic_days" && value === "") {
            setDaysOfMonth(true)
        }
        if (name === "periodic_day_month" && value !== "") {
            
            setDays(false)
            if (value < 1){
                value = 1
                showAlert('warning', {
                    title: "Please choose in between 1-30.",
                    timer: 3000,
                });
            }
            else if(value > 30){
                value = 30
                showAlert('warning', {
                    title: "Please choose in between 1-30.",
                    timer: 3000,
                });
            }
            
        }
        else if (name === "periodic_day_month" && value === "") {
            setDays(true)
        }
        setNewTask((prevData) => ({ ...prevData, [name]: value }));
    }

    function getTaskData(param) {
        var token = Cookies.get('_auth');
        axiosInstance.get('accounts/periodic_tasks?tenant_id=' + param, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    console.log("data", data)
                    setperiodicData(data);
                }
            })
            .catch((err) => {
                console.error("Error fetching tenants:", err);
            });
    }
    function GetUserGroups(param) {
        var token = Cookies.get('_auth');
        axiosInstance.get('add_groups?tenant_id=' + param, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    console.log("data", data)
                    setUserGroup(data);
                }
            })
            .catch((err) => {
                console.error("Error fetching tenants:", err);
            });
    }
    useEffect(() => {
        const cookieValue = Cookies.get('_auth_state');
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
        console.log("cookies", authstate["role"])
        setAccessType(authstate["access"])
        if (authstate["access"] === "all") {
            setRole("superuser")
            setShowdiv(true)
            var token = Cookies.get('_auth');
            // console.log("token", token)
            // var session = Cookies.get('sessionid');
            // console.log("cookiessss", session)
            // const yourCookie = 'sessionid='+session+';';
            // console.log("cookie", yourCookie)
            axiosInstance.get('/accounts/add_tenant/', {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Cookie': yourCookie,
                    'Authorization': `Bearer ${token}`,
                },
                withCredentials: true
            },
            )
                .then((res) => {
                    if (res.status === 200) {
                        let data = res.data.data;
                        setTenant(data);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching tenants:", err);
                });
        }
        else if (authstate["access"] === "users") {
            setUserTenant(authstate['company'])
            setRole("system")
            setSelectedTenant(authstate['company'])
            setTenantfortask(authstate['company'])
            getTaskData(authstate['company'])
            GetUserGroups(authstate['company'])
        }

    }, []);
    const [deleteId, setDeleteId] = useState(null)
    const task_action = (id, action_type) => {
        console.log("id", id, action_type)
        if(action_type === "edit"){
        var params = {
            "id": id,
            "action": action_type
        }
        var token = Cookies.get('_auth');
        axiosInstance.put('accounts/periodic_tasks/', params, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    console.log("data", data)
                    getTaskData(selected_tenant)
                    showAlert('success', {
                        title: res.data.message,
                        timer: 3000,
                    });
                }
            })
            .catch((err) => {
                console.error("Error fetching tenants:", err);
                showAlert('error', {
                    title: err.response.data.message,
                    timer: 3000,
                });
            });
        }
        else if(action_type === "del"){
            setDeleteId(id)
            openDeleteConfirmation()
        }

    }
    const handleSubmitDelete = () => {
        if(deleteId !== null){
        var params = {
            "id": deleteId,
            "action": "del"
        }
        var token = Cookies.get('_auth');
        axiosInstance.put('accounts/periodic_tasks/', params, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    console.log("data", data)
                    getTaskData(selected_tenant)
                    showAlert('success', {
                        title: res.data.message,
                        timer: 3000,
                    });
                    closeDeleteConfirmation()
                }
            })
            .catch((err) => {
                console.error("Error fetching tenants:", err);
                showAlert('error', {
                    title: err.response.data.message,
                    timer: 3000,
                });
            });
        }
    }
    const [senderaccount, setSenderaccount] = useState("")
    const [receiveraccount, setReceiveraccount] = useState("")
    const handlechangesender = (e) => {
        setSenderaccount(e.target.value)
    }
    const handlechangereceiver = (e) => {
        setReceiveraccount(e.target.value)

    }
    function title(type, account_id){
        var token = Cookies.get('_auth');
      axiosInstance.get('accounts/get_title?account_id=' + account_id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          console.log("res", res)
          if (res.status === 200) {
            if(type==="sender"){
                console.log("sender")
                setSendercredentials(res.data.data)
            }
            else if(type==="reciever"){
                console.log("receiver")
                setReceivercredentials(res.data.data)
            }
          } else {
            showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.error("Error fetching account details:", err);
          showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
        });
    }
    const getTitle = (callfrom) => {
        if(callfrom === "sender"){
            if(senderaccount !== ""){
                title(callfrom, senderaccount)
            }
            else{
                showAlert('warning', {
                    title: "Please enter account number",
                    timer: 3000,
                  });
            }
        }
        else if(callfrom === "reciever"){
            if(receiveraccount !== ""){
                title(callfrom, receiveraccount)
            }
            else{
                showAlert('warning', {
                    title: "Please enter account number",
                    timer: 3000,
                  });
            }
        }
    }
    const changetitle = (callfrom) => {
        if(callfrom === "sender"){
            setSenderaccount("")
            setSendercredentials({
                "id": "",
                "userId": ""
                
            })

        }
        else if(callfrom === "reciever"){
            setReceiveraccount("")
            setReceivercredentials({
                "id": "",
                "userId": ""
            
        })
        }
    }
    return (
        <>
            <Layout />
            <div className="container" style={{ marginTop: "80px", display: "flex", flexDirection: "column", width: "50%" }}>
                {showDiv && (<div className="form-field mb-3" id="choose_tenant" >
                    <label htmlFor="fileInput" className="form-label"><h2>Choose Tenant</h2></label>
                    <div className="input-group">
                        <select
                            className="form-control form-control-sm"
                            id="fileInput"
                            style={{ margin: "auto" }}
                            onChange={handleTenantChange}
                            value={selected_tenant}
                        >
                            <option value="">Select an option</option>
                            {tenat.map((tenant) => (
                                <option key={tenant.id} value={tenant.companyName}>
                                    {tenant.companyName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>)}
                <div className="row">
                    <div className="col-6">
                        <h2>Periodic Transactions</h2>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-success" onClick={openModaltask}>Add New Task</button>
                    </div>
                </div>

                <table className="table w-100">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Sender Account</th>
                            <th>Reciever Account</th>
                            <th>Description</th>
                            <th>Periodeic Days </th>
                            <th>Periodic Date</th>
                            <th>Amount</th>
                            <th>Active</th>
                            {/* <th>Tenant ID</th> */}

                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getperiodicdata.map((account, index) => (
                            <tr key={account.id}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">{account.fromAccount}</td>
                                <td className="text-center">{account.toAccount}</td>
                                <td className="text-center">{account.description}</td>
                                <td className="text-center">{account.periodic_days}</td>
                                <td className="text-center">{account.periodic_day_month}</td>
                                <td className="text-center">{account.transaction_amount}</td>
                                <td className="text-center">{account.active ? 'Enabled' : 'Disabled'}</td>
                                <td>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-success" onClick={() => task_action(account.id, "edit")}>
                                                {account.active ? 'Disable' : 'Enable'}
                                            </button></div>
                                        <div className="col">
                                            <button className="btn btn-danger" onClick={() => task_action(account.id, "del")}>
                                                Delete
                                            </button></div>
                                    </div>
                                </td>                            </tr>
                        ))}
                    </tbody>
                </table>
                {openperiodicmodal && (
                    <div
                        className={`modal show`}
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="addTenantModalLabel"
                        aria-hidden={!openperiodicmodal}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addTenantModalLabel">
                                        Transfer Money
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeModaltask}
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                                    {showDiv && (<div className="form-field mb-3" id="choose_tenant" >
                                        <label htmlFor="fileInput" className="form-label">Choose Tenant</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-sm"
                                                id="fileInput"
                                                style={{ margin: "auto" }}
                                                onChange={handleTenantChangeTask}
                                                value={tenantfortask}
                                            >
                                                <option value="">Select an option</option>
                                                {tenat.map((tenant) => (
                                                    <option key={tenant.id} value={tenant.companyName}>
                                                        {tenant.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>)}
                                    {/* Form fields for new tenant */}
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Send From:
                                        </label>
                                        <div className="row">
                                            <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="fromAccount"
                                            name="fromAccount"
                                            value={senderaccount}
                                            maxLength="200"
                                            onChange={handlechangesender}
                                            disabled={sendercredentials.userId !== ""}
                                        />
                                        </div>
                                        <div className="col-3"><button className="btn btn-success" onClick={() => getTitle("sender")}  disabled={sendercredentials.userId !== ""}>Get Title</button></div>
                                        <div className="col-3"><button className="btn btn-success" onClick={()=> changetitle("sender")} disabled={sendercredentials.userId === ""}>Change</button></div>
                                        </div>
                                        {sendercredentials.userId !== "" && (<div className="row">
                                    <div className="col-4">Sender Title</div>
                                    <div className="col-8">{sendercredentials.userId}</div>
                                    </div>)}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Send To:
                                        </label>
                                        <div className="row">
                                            <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="toAccount"
                                            name="toAccount"
                                            value={receiveraccount}
                                            maxLength="200"
                                            onChange={handlechangereceiver}
                                            disabled={receivercredentials.userId !== ""}

                                        />
                                        </div>
                                        <div className="col-3"><button className="btn btn-success" onClick={() => getTitle("reciever")} disabled={receivercredentials.userId !== ""}>Get Title</button></div>
                                        <div className="col-3"><button className="btn btn-success" onClick={()=> changetitle("reciever")} disabled={receivercredentials.userId === ""}>Change</button></div>
                                    </div>
                                    {receivercredentials.userId !== "" && (<div className="row">
                                    <div className="col-4">Reciever Title</div>
                                    <div className="col-8">{receivercredentials.userId}</div>
                                    </div>)}
                                    </div>
                                    {showdays && (<div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            After Days:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="periodic_days"
                                            name="periodic_days"
                                            value={newtask.periodic_days}
                                            maxLength="200"
                                            onChange={handleChangenewtask}

                                        />
                                    </div>)}
                                    {showdaysofmonth && (<div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            On Day every Month:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="periodic_day_month"
                                            name="periodic_day_month"
                                            value={newtask.periodic_day_month}
                                            maxLength="200"
                                            onChange={handleChangenewtask}
                                            min="1"  // Restrict minimum value to 1
                                            max="30" // Restrict maximum value to 30

                                        />
                                    </div>)}
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Description:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            value={newtask.description}
                                            onChange={handleChangenewtask}
                                            maxLength="20"

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Amount:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="transaction_amount"
                                            name="transaction_amount"
                                            value={newtask.transaction_amount}
                                            onChange={handleChangenewtask}
                                            maxLength="20"

                                        />
                                    </div>
                                    <div className="form-field mb-3" id="choose_tenant" >
                                        <label htmlFor="fileInput" className="form-label">Approval1</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-sm"
                                                id="approvalRequired1"
                                                name="approvalRequired1"
                                                style={{ margin: "auto" }}
                                                onChange={handleChangenewtask}
                                                value={newtask.approvalRequired1}
                                            >
                                                <option value="not_required">Not Required</option>
                                                <option value="approval">Approval Required</option>
                                                <option value="validation">Validation Required</option>

                                            </select>
                                        </div>
                                    </div>
                                    {newtask.approvalRequired1 !== "not_required" && (<div className="form-field mb-3" id="choose_tenant" >
                                        <label htmlFor="fileInput" className="form-label">Approval1 Group</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-sm"
                                                id="group1"
                                                name="group1"
                                                style={{ margin: "auto" }}
                                                onChange={handleChangenewtask}
                                                value={newtask.group1}
                                            >
                                                <option value="">Select an option</option>
                                                {userGroup.map((tenant) => (
                                                    <option key={tenant.id} value={tenant.group_name}>
                                                        {tenant.group_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>)}
                                    <div className="form-field mb-3" id="choose_tenant" >
                                        <label htmlFor="fileInput" className="form-label">Approval2</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-sm"
                                                id="approvalRequired2"
                                                name="approvalRequired2"
                                                style={{ margin: "auto" }}
                                                onChange={handleChangenewtask}
                                                value={newtask.approvalRequired2}
                                            >
                                                <option value="not_required">Not Required</option>
                                                <option value="approval">Approval Required</option>
                                                <option value="validation">Validation Required</option>

                                            </select>
                                        </div>
                                    </div>
                                    {newtask.approvalRequired2 !== "not_required" && (<div className="form-field mb-3" id="choose_tenant" >
                                        <label htmlFor="fileInput" className="form-label">Approval2 Group</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-sm"
                                                id="group2"
                                                name="group2"
                                                style={{ margin: "auto" }}
                                                onChange={handleChangenewtask}
                                                value={newtask.group2}
                                            >
                                                <option value="">Select an option</option>
                                                {userGroup.map((tenant) => (
                                                    <option key={tenant.id} value={tenant.group_name}>
                                                        {tenant.group_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>)}

                                    {/* Add similar fields for postCode, address, phoneNo, and companyName */}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={handleSubmitTask}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={closeModaltask}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>)}
                {deleteConfirmation && (
                    <div
                        className={`modal show`}
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="addTenantModalLabel"
                        aria-hidden={!deleteConfirmation}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addTenantModalLabel">
                                        Delete Confirmation
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeDeleteConfirmation}
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                                    
                                    {/* Form fields for new tenant */}
                                    <div className="mb-3">
                                        Are you sure?</div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={handleSubmitDelete}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={closeDeleteConfirmation}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>)}
            </div>
        </>
    )
};
export default PeriodicTransactions;