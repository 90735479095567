import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_users } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';
import { update_user } from "../../Apis/network";
import { update_tenant } from "../../Apis/network";

const EditTenantWidget = () => {
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
  const [tenat, setTenant] = useState([]);
  const [userTenant, setUserTenant] = useState("");
  const [editTenant, setEditTanent] = useState({})
  const [selectedTanent, setSelectedTanent] = useState("");
  const [useroptions, setUseroptions] = useState([]);
  const [selecteduser, setSelecteduser] = useState("")
  
    const handleChange = (e) => { 
        const { name, value } = e.target;
        setEditTanent((prevData) => ({ ...prevData, [name]: value }));

    }
  const handleChangeAccount = (e) => {
    const { name, value } = e.target;
    if(name==="tenantId"){
        if(value === ""){
            setUseroptions([])
        }
        else{
        setSelectedTanent(e.target.value)
        var selectedUserObj = tenat.find(user => user.companyName === value);
        console.log("selected obj", selectedUserObj)
        setEditTanent(selectedUserObj || {});
      
    }}
  };
  
  const handleSubmit = () => {
    // Handle the submission logic here
    console.log("Form submitted with the following data:");
    // console.log(editUser);
    update_tenant(editTenant)
    .then((res) =>{
        if (res.status === 200){
            let data = res.data.data;
            setEditTanent(data);
            showAlert('success', {
                title: "Tenant Updated",
                timer: 3000,
              });
              get_tenants()
            .then((res) => {
              if (res.status === 200) {
                let data = res.data.data;
                setTenant(data);
              }
            })
            .catch((err) => {
              console.error("Error fetching tenants:", err);
            });
            
          }
    })
    .catch((err) => {
        console.error("Error Updating User", err);
        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
      });
    // You might want to call an API or update some state here
};

    useEffect(() => {
  
        const cookieValue = Cookies.get('_auth_state');
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    
        if (authstate["role"] === "superuser") {
          setRole("superuser");
          get_tenants()
            .then((res) => {
              if (res.status === 200) {
                let data = res.data.data;
                setTenant(data);
              }
            })
            .catch((err) => {
              console.error("Error fetching tenants:", err);
            });
        } 
      }, []);
    return (
        <>
        <div className="m-5">
        {role === 'superuser' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={selectedTanent}
              onChange={handleChangeAccount}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            
          
          {editTenant && editTenant.id && (
          <>
          
            <div className="mb-3">
                <label htmlFor="firstName" className="form-label">Company Name:</label>
                <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    id="companyName"
                    value={editTenant.companyName}
                    // onChange={handleChange}
                    disabled
                />
            </div>
            
            <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                <input
                    type="text"
                    className="form-control"
                    name="phoneNo"
                    id="phoneNo"
                    value={editTenant.phoneNo}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Address:</label>
                <input
                    type="text"
                    className="form-control"
                    name="address"
                    id="address"
                    value={editTenant.address}
                    onChange={handleChange}
                />
            </div>
            
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Post Code:</label>
                <input
                    type="text"
                    className="form-control"
                    name="postCode"
                    id="postCode"
                    value={editTenant.postCode}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <button 
                    type="button" 
                    className="btn btn-success" 
                    onClick={handleSubmit}
                >
                    Edit
                </button>
            </div>
            </>
          )}
        </div>
        
         </>
  );
  };

export default EditTenantWidget;