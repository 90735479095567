import React, { useEffect, useState } from "react";
import Layout from "./nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import useSweetAlert from "../Alerts/useSweetAlert";
import { useNavigate, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';
import axiosInstance from "../Apis/network2";
function Tenants()  {
  const showAlert = useSweetAlert();

    const [tenants, setTenants] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [editrecord, setEditrecord] = useState({
      "id": "",
      "companyName": "",
      "email": "",
      "phoneNo": "",
      "address": "",
      "postCode": "",
    })

    const get_Tenants = () => {
      var token = Cookies.get('_auth');
       
      axiosInstance.get('/accounts/add_tenant/',{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        setTenants(data);
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
    });
    }
    useEffect(() => {
        const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["role"])
    
    if(authstate["access"] === "all"){
    //   setRole("superuser")
      
    //     setShowDiv(true)
    //     console.log("show div", showDiv)
    get_Tenants()
    }
    }, []);

    const statuschange = (id, status) => {
      var token = Cookies.get('_auth');
      var params = {
        "tenantId": id,
        "active": status
      }
       
      axiosInstance.post('tenant_active/', params,{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        showAlert('success', {
          title: res.data.message,
          timer: 3000,
        });
        get_Tenants()
        
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });
    });
    }
    const handleChangeedittenant = (e) => {
      var { name, value } = e.target;
      setEditrecord((prevData) => ({ ...prevData, [name]: value }));
    }
    const openEditModal = (accountId) => {
      var recordToEdit = tenants.find(record => record.id === accountId);
      console.log("aaaa", recordToEdit)
      setEditrecord(recordToEdit)
      setEditModal(true)
    }
    const closeEditModal = () => {
      setEditModal(false)
      setEditrecord({
        "id": "",
      "companyName": "",
      "email": "",
      "phoneNo": "",
      "address": "",
      "postCode": "",
      })
    }
    const editSubmit = () => {
    console.log("submit", editrecord)
    if (editrecord.id !== "" && (editrecord.companyName !== null && editrecord.companyName !== "") && (editrecord.email !== null && editrecord.email !== "") && (editrecord.phoneNo !== null && editrecord.phoneNo !== "") && (editrecord.address !== null && editrecord.address !== "") && (editrecord.postCode !== null && editrecord.postCode !== "")){
      var token = Cookies.get('_auth');
      axiosInstance.put('accounts/add_tenant/', editrecord,{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        showAlert('success', {
          title: res.data.message,
          timer: 3000,
        });
        get_Tenants()
        closeEditModal()
        
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });
    })
  }
    else{
      showAlert('warning', {
        title: "All fields are required.",
        timer: 3000,
      });
    }
    }
    return (
        <>
        <Layout />
      <div className="container" style={{ marginTop: "80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <table className="table w-100">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Name</th>
                <th className="text-center">Email</th>
                <th className="text-center">Address</th>
                <th className="text-center">Post code</th>
                <th className="text-center">Active</th>
                <th className="text-center">Enable/Disable</th>
                <th className="text-center">Action</th>
                
              </tr>
            </thead>
            <tbody>
              {tenants.map((account) => (
                <tr key={account.id}>
                  <td className="text-center">{account.id}</td>
                  <td className="text-center">{account.companyName}</td>
                  <td className="text-center">{account.email}</td>
                  <td className="text-center">{account.address}</td>
                  <td className="text-center">{account.postCode}</td>
                  <td className="text-center">{account.isActive ? "Yes" : "No"}</td>
                  <td className="text-center"><button className="btn btn-success" onClick={() => statuschange(account.id, !account.isActive)}>{account.isActive ? 'Disable' : 'Enable'}</button></td>
                  <td className="text-center"> <button className="btn btn-success" onClick={() => openEditModal(account.id)} >Edit</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          {editModal && (
                    <div
                        className={`modal show`}
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="addTenantModalLabel"
                        aria-hidden={!editModal}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addTenantModalLabel">
                                        Transfer Money
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeEditModal}
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                                    
                                    {/* Form fields for new tenant */}
                                    
                                    
                                    
                                    
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Name:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyName"
                                            name="companyName"
                                            value={editrecord.companyName}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"
                                            disabled

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Email:
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={editrecord.email}
                                            onChange={handleChangeedittenant}
                                            maxLength="30"

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Phone Number:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            name="phoneNo"
                                            value={editrecord.phoneNo}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Address:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            value={editrecord.address}
                                            onChange={handleChangeedittenant}
                                            

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Postal Code:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="postCode"
                                            name="postCode"
                                            value={editrecord.postCode}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"

                                        />
                                    </div>
                                    
                                    
                                    
                                    

                                    {/* Add similar fields for postCode, address, phoneNo, and companyName */}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={editSubmit}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={closeEditModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>)}
      </div>

        </>
    );
  }

export default Tenants;