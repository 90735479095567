import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_users } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';
import { active_deactive_users } from "../../Apis/network";


const ViewUserWidget = () => {
  const showAlert = useSweetAlert();
  const [role, setRole] = useState("");
  const [tenat, setTenant] = useState([]);
  const [userTenant, setUserTenant] = useState("");
  const [useroptions, setUseroptions] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const getUsers = ((authstate) => {
    get_users(authstate)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setUseroptions(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
  })
  useEffect(() => {
  
    const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};

    if (authstate["role"] === "superuser") {
      setRole("superuser");
      get_tenants()
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setTenant(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
    } else if (authstate["role"] === "system") {
      setUserTenant(authstate['company']);
      setRole("system");
      getUsers(authstate['company'])
    //   get_users(authstate['company'])
    //     .then((res) => {
    //       if (res.status === 200) {
    //         let data = res.data.data;
    //         setUseroptions(data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error("Error fetching tenants:", err);
    //     });
    }
  }, []);
  const handleCheckboxChange = (id, e) => {
    console.log("checkbox", id, e.target.checked)
    var active_check
    if (e.target.checked){
       active_check = true
    }else{
       active_check = false
    }
    var params={
      "userId": id,
      "active": active_check
    }
    active_deactive_users(params)
        .then((res) => {
          if (res.status === 200) {
            const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    if (authstate["role"] === "superuser") {
            getUsers(selectedTenant)
    }else{
      getUsers(authstate['company'])
    }
          //   let data = res.data.data;
          //   setUseroptions(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
  }
  const handleChangeAccount = (e) => {
    setSelectedTenant(e.target.value);
    get_users(e.target.value)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setUseroptions(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
  };

  // ... (your existing code)

return (
    <>
      <div
        className={`modal show`}
        style={{ display: "block" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addTenantModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTenantModalLabel">
                Users
              </h5>
            </div>
            <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {/* Your existing content */}
              {role === 'superuser' && (
                <div className="mb-3">
                  <label htmlFor="accountType" className="form-label">
                    Tenant*:
                  </label>
                  <select
                    className="form-control"
                    id="tenantId"
                    name="tenantId"
                    value={selectedTenant}
                    onChange={handleChangeAccount}
                  >
                    <option value="">Select a tenant</option>
                    {tenat.map((user) => (
                      tenat.length > 0 && (
                        <option key={user.id} value={user.companyName}>
                          {user.companyName}
                        </option>
                      )
                    ))}
                  </select>
                </div>
              )}
  
              {useroptions.length > 0 && (
                <div>
                  <table className="table">
                  <thead>
                      <tr>
                        <th>Username</th>
                        <th>Tenant ID</th>
                        <th>Groups</th>
                        <th>Active/Inactive</th>
                      </tr>
                    </thead>
                    <tbody>
                      {useroptions.map((user) => (
                        <tr key={user.id}>
                          <td>{user.username}</td>
                          <td>{user.tenantId}</td>
                          <td>{user.groups}</td>
                          <td><input type="checkbox"  data-toggle="toggle" checked={user.is_active} onChange={(e) => handleCheckboxChange(user.id, e)}/></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  };

export default ViewUserWidget;
