import logo from './logo.svg';
import './App.css';
import Welcome from './pages/loginfolder/login';
import Dashboard from './pages/dashboard/dashboard';
import { refreshToken } from './Apis/network';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import Layout from './pages/nav/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RequireAuth } from "react-auth-kit";
import AccountDetails from './pages/dashboard/account_details';
import NewTenantModal from './pages/dashboard/NewTenantModal';
import LoginWidget from './pages/widgets/login_widget';
import CreateUserWidget from './pages/widgets/createuser_account_widget';
import CreateAccountWidget from './pages/widgets/createaccount_widget';
import ViewUserWidget from './pages/widgets/viewuser_widget';
import ViewTenantWidget from './pages/widgets/view_tenants_widget';
import EditUserWidget from './pages/widgets/edituser_widget';
import EditTenantWidget from './pages/widgets/edittenant_widget';
import ViewTransactionWidget from './pages/widgets/viewtransaction_widget';
import ForgotPassword from './pages/forgot/forgot_password';
import PasswordResetCheck from './pages/forgot/password-reset-view';
import LoginWidgetTransaction from './pages/widgets/login_widget_transaction';
import RestrictedDashboard from './pages/dashboard/restricted_dashboard';
import UserProfile from './pages/profiles/profile';
import PeriodicTransactions from './pages/dashboard/peridiodic_transactions';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard2 from './pages/dashboard/dash2';
import UserAccounts from './pages/dashboard/user_accounts';
import { useIsAuthenticated  } from 'react-auth-kit';
import Tenants from './pages/tenants';

function App() {
  

  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/password-reset-check/:uidb64/:token" element={<PasswordResetCheck />} />
      <Route path="/loginwidget" element={<LoginWidget />} />
      <Route path="/loginwidgettransaction" element={<LoginWidgetTransaction />} />
      {/* <Route path="/dash2" element={<RequireAuth loginPath={'/'}><Dashboard /></RequireAuth>} /> */}
      <Route path="/dash" element={<RequireAuth loginPath={'/'}><RestrictedDashboard /></RequireAuth>} />

      <Route path="/user_accounts" element={<RequireAuth loginPath={'/'}><UserAccounts /></RequireAuth>} />
      <Route path="/tenants" element={<RequireAuth loginPath={'/'}><Tenants /></RequireAuth>} />
      <Route path="/account_details" element={<RequireAuth loginPath={'/'}><AccountDetails /></RequireAuth>} />
      <Route path="/new_tenant_modal" element={<RequireAuth loginPath={'/'}><NewTenantModal /></RequireAuth>} />
      <Route path="/profile" element={<RequireAuth loginPath={'/'}><UserProfile /></RequireAuth>} />
      <Route path="/periodic" element={<RequireAuth loginPath={'/'}><PeriodicTransactions /></RequireAuth>} />


      <Route path="/create_user_widget" element={<CreateUserWidget />} />
      <Route path="/create_account_widget" element={<CreateAccountWidget />} />
      <Route path="/view_users_widget" element={<ViewUserWidget />} />
      <Route path="/view_tenant_widget" element={<ViewTenantWidget />} />
      <Route path="/edit_user_widget" element={<EditUserWidget />} />
      <Route path="/edit_tenant_widget" element={<EditTenantWidget />} />
      <Route path="/login_transaction_widget" element= {<LoginWidgetTransaction />}/>
      <Route path="/view_transaction_widget" element= {<ViewTransactionWidget />}/>
    </Routes>
  );
}
export default App;
