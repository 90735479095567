import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_users } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';
import { update_user } from "../../Apis/network";

const EditUserWidget = () => {
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
  const [tenat, setTenant] = useState([]);
  const [userTenant, setUserTenant] = useState("");
  const [editUser, setEditUser] = useState({})
  const [selectedTanent, setSelectedTanent] = useState("");
  const [useroptions, setUseroptions] = useState([]);
  const [selecteduser, setSelecteduser] = useState("")
  
    const handleChange = (e) => { 
        const { name, value } = e.target;
        setEditUser((prevData) => ({ ...prevData, [name]: value }));

    }
  const handleChangeAccount = (e) => {
    const { name, value } = e.target;
    if(name === "userId"){
        setSelecteduser(value)
        var selectedUserObj = useroptions.find(user => user.id === parseInt(value));
        console.log("selected obj", selectedUserObj)
        setEditUser(selectedUserObj || {});
        // setSelecteduser(e.target.value)
    }
    // setCreateAccount((prevData) => ({ ...prevData, [name]: value }));
    if(name==="tenantId"){
        if(value === ""){
            setUseroptions([])
        }
        else{
        setSelectedTanent(e.target.value)
      get_users(value)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              setUseroptions(data);
              console.log("abcd", data)
            }
            else{
                console.log("in else")
            }
          })
          .catch((err) => {
            console.error("Error fetching tenants:", err);
          });
    }}
  };
  const getUsers = ((authstate) => {
    get_users(authstate)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setUseroptions(data);
            console.log("abc", data)
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
  })
  const handleSubmit = () => {
    // Handle the submission logic here
    console.log("Form submitted with the following data:");
    console.log(editUser);
    update_user(editUser)
    .then((res) =>{
        if (res.status === 200){
            let data = res.data.data;
            setEditUser(data);
            getUsers(userTenant)
            showAlert('success', {
                title: "User Updated",
                timer: 3000,
              });
          }
    })
    .catch((err) => {
        console.error("Error Updating User", err);
        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
      });
    // You might want to call an API or update some state here
};

    useEffect(() => {
  
        const cookieValue = Cookies.get('_auth_state');
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    
        if (authstate["role"] === "superuser") {
          setRole("superuser");
          get_tenants()
            .then((res) => {
              if (res.status === 200) {
                let data = res.data.data;
                setTenant(data);
              }
            })
            .catch((err) => {
              console.error("Error fetching tenants:", err);
            });
        } else if (authstate["role"] === "system") {
          setUserTenant(authstate['company']);
          setRole("system");
          getUsers(authstate['company'])
        //   get_users(authstate['company'])
        //     .then((res) => {
        //       if (res.status === 200) {
        //         let data = res.data.data;
        //         setUseroptions(data);
        //       }
        //     })
        //     .catch((err) => {
        //       console.error("Error fetching tenants:", err);
        //     });
        }
      }, []);
    return (
        <>
        <div className="m-5">
        {role === 'superuser' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={selectedTanent}
              onChange={handleChangeAccount}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              User*:
            </label>
            <select
              className="form-control"
              id="userId"
              name="userId"
              value={selecteduser}
              onChange={handleChangeAccount}
              disabled={role === 'superuser' && useroptions.length === 0}
            >
              <option value="">Select a user</option>
              {useroptions.map((user) => (
              useroptions.length > 0 && (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              )
            ))}
            </select>
          </div>
          
          {editUser && editUser.id && (
          <>
          <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={editUser.email}
                    disabled
                />
            </div>
            <div className="mb-3">
                <label htmlFor="firstName" className="form-label">First Name:</label>
                <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    id="first_name"
                    value={editUser.first_name}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Last Name:</label>
                <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    id="last_name"
                    value={editUser.last_name}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                <input
                    type="text"
                    className="form-control"
                    name="phoneNo"
                    id="phoneNo"
                    value={editUser.phoneNo}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Address:</label>
                <input
                    type="text"
                    className="form-control"
                    name="address1"
                    id="address1"
                    value={editUser.address1}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Date of birth:</label>
                <input
                    type="date"
                    className="form-control"
                    name="dob"
                    id="dob"
                    value={editUser.dob}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Post Code:</label>
                <input
                    type="text"
                    className="form-control"
                    name="postCode"
                    id="postCode"
                    value={editUser.postCode}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <button 
                    type="button" 
                    className="btn btn-success" 
                    onClick={handleSubmit}
                >
                    Edit
                </button>
            </div>
            </>
          )}
        </div>
        
         </>
  );
  };

export default EditUserWidget;