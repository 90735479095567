import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_account_details, get_account_transaction } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";

import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Apis/network2";


const UserProfile = () => {
    const showAlert = useSweetAlert();
    const [userId, setUserId] = useState("")
    const [userdata, setUserdata] = useState({
        "id": 0,
        "username": "",
        "first_name": "",
        "last_name": "",
        "dob": "",
        "tenantId": "",
        "is_active": "",
        "email": "",
        "phoneNo": "",
        "address1": "",
        "postCode": ""
    })
    const settingUser = () => {
        const cookieValue = Cookies.get('_auth_state');
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
        if (authstate){
        var user_id = authstate["id"]
        setUserId(user_id)
            var tenant = authstate["company"]
            var token = Cookies.get('_auth');
            axiosInstance.get('get_user?userId=' + user_id + '&tenantId='+ tenant,{
                headers:{
                  'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
                }
              }).then((res) => {
                if (res.status === 200) {
                  let data = res.data.data;
                  console.log("data", data)
                  setUserdata(data);
                  setEditUser(data)
                }
              })
              .catch((err) => {
                console.error("Error fetching tenants:", err);
              });
            }
    }

    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        
        
        // console.log("cookies", authstate["role"])
        
            settingUser()
            const today = new Date();
            // Format the date as YYYY-MM-DD
            const formattedDate = today.toISOString().split('T')[0];
            // Set the current date state
            setCurrentDate(formattedDate);
        
    }, []);
    const [showModalPassword, setShowModalPassword] = useState(false)
    const [changepassword, setChangePassword] = useState({
        "old_password": "",
        "new_password": "",
        "new_password_confirm": ""
    })
    const openpasswordmodal = () => {
        setShowModalPassword(true)
    }
    const closepasswordmodal = () => {
        setShowModalPassword(false)
    }
    const handlechangepassword = (e) => {
        const { name, value } = e.target;
        setChangePassword((prevData) => ({ ...prevData, [name]: value }));
    }
    const submitPassword = () => {
        console.log("details", changepassword)
        if(changepassword.old_password !== "" && changepassword.new_password !== "" && changepassword.new_password_confirm !== ""){
            if (changepassword.new_password === changepassword.new_password_confirm){
                var token = Cookies.get('_auth');
                axiosInstance.post('change_password/', changepassword, {
                    headers:{
                      'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                    }
                  }).then((res) => {
                    if (res.status === 200) {
                        showAlert('success', {
                            title: res.data.message,
                            timer: 3000,
                          });
                          
                          closepasswordmodal()
                          setChangePassword({
                            "old_password": "",
                            "new_password": "",
                            "new_password_confirm": ""
                    })
                    }
                    else{
                        showAlert('error', {
                            title: res.data.message,
                            timer: 3000,
                          });
                    }
                  })
                  .catch((err) => {
                    console.error("Error fetching tenants:", err);
                    showAlert('error', {
                        title: err.response.data.message,
                        timer: 3000,
                      });
                  });
            }
            else{
                showAlert('warning', {
                    title: "New Password and Confirm Password doesn't match.",
                    timer: 3000,
                  });
            }

        }
        else{
            showAlert('warning', {
                title: "Please fill all required fields.",
                timer: 3000,
              });
        }
    }
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [edituser, setEditUser] = useState({
        "id": "",
        "first_name": "",
        "last_name": "",
        "phoneNo": "",
        "address1": "",
        "postCode": "",
        "dob": null,

    })
    const openeditmodal = () => {
        setShowModalEdit(true)
    }
    const closeeditmodal = () => {
        setShowModalEdit(false)
    }
    const handleedituser = (e) => {
        const { name, value } = e.target;
        setEditUser((prevData) => ({ ...prevData, [name]: value }));
    }
    const submitedituser = () => {
            console.log("edit user", edituser)
            if (userId !== null && edituser.last_name !== "" && edituser.dob !== null && edituser.phoneNo !== "" && edituser.address1 !== ""){
                var token = Cookies.get('_auth');
                var params = {
                    "id": userId,
                    "first_name": edituser.first_name,
                    "last_name": edituser.last_name,
                    "phoneNo": edituser.phoneNo,
                    "address1": edituser.address1,
                    "postCode": edituser.address1,
                    "dob": edituser.dob,
                    "postCode": edituser.postCode
                }
                axiosInstance.put('get_user/', params, {
                    headers:{
                      'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                    }
                  }).then((res) => {
                    if (res.status === 200) {
                        showAlert('success', {
                            title: res.data.message,
                            timer: 3000,
                          });
                          
                          closeeditmodal()
                          settingUser()
                    }
                    else{
                        showAlert('error', {
                            title: res.data.message,
                            timer: 3000,
                          });
                    }
                  })
                  .catch((err) => {
                    console.error("Error fetching tenants:", err);
                    showAlert('error', {
                        title: err.response.data.message,
                        timer: 3000,
                      });
                  });

            }
            else{
                showAlert('error', {
                    title: "Please fill all fields.",
                    timer: 3000,
                  });
            }
    }
    return (
        <>
          <Layout />
          <div className="container" style={{ marginTop: "80px", display: "flex", flexDirection: "column", width: "50%"}}>
                       
                       <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Company name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.tenantId}
                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.first_name}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.last_name}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Role:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.groups}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                DOB:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.dob}                // onChange={handleChange}
                max={currentDate}
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.email}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Phone Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.phoneNo}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Address:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={userdata.address1}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Post Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="postCode"
                name="postCode"
                value={userdata.postCode}                // onChange={handleChange}
                maxlength="200"
                disabled
              />
            </div>
            <div className="row">
            <div className="col-6" style={{ display: "flex", justifyContent: "flex-end"}}>
            <button className="btn btn-success" style={{ width: "50%" }} onClick={openeditmodal}>Edit Details</button></div>
            <div className="col-6">
            <button className="btn btn-success" style={{ width: "50%" }} onClick={openpasswordmodal}>Change Password</button></div>
            </div>
          </div>
          { showModalPassword && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModalPassword}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Change Password Modal
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closepasswordmodal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Old Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="old_password"
                name="old_password"
                value={changepassword.old_password}
                maxlength="200"
                onChange={handlechangepassword}

              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                New Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="new_password"
                name="new_password"
                value={changepassword.new_password}
                maxlength="20"
                onChange={handlechangepassword}

              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Confirm New Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="new_password_confirm"
                name="new_password_confirm"
                value={changepassword.new_password_confirm}
                onChange={handlechangepassword}
                maxlength="20"
                
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={submitPassword}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={closepasswordmodal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    { showModalEdit && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModalEdit}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Edit Profile
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeeditmodal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                value={edituser.first_name}
                maxlength="200"
                onChange={handleedituser}

              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={edituser.last_name}
                maxlength="20"
                onChange={handleedituser}

              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Date of birth:
              </label>
              <input
                type="date"
                className="form-control"
                id="dob"
                name="dob"
                value={edituser.dob}
                onChange={handleedituser}
                max={currentDate}
                
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Phone Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNo"
                name="phoneNo"
                value={edituser.phoneNo}
                onChange={handleedituser}
                maxlength="20"
                
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Address:
              </label>
              <input
                type="text"
                className="form-control"
                id="address1"
                name="address1"
                value={edituser.address1}
                onChange={handleedituser}
                maxlength="20"
                
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Post Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="postCode"
                name="postCode"
                value={edituser.postCode}
                onChange={handleedituser}
                maxlength="20"
                
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={submitedituser}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={closeeditmodal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
          </>
    )
}
export default UserProfile;