// src/components/ForgotPassword.js

import React, { useState } from 'react';
import axios from 'axios';
// import { forgot_service } from '../../Apis/network';
import './ForgotPassword.css'; // Import your CSS file for styling
import { forgot_service } from '../../Apis/network';
import useSweetAlert from "../../Alerts/useSweetAlert";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const showAlert = useSweetAlert();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () => {
    if (email !== ""){
    forgot_service({"email": email})
    .then((res) => {
      console.log("res", res)
      setMessage(res.data.message);
      showAlert('success', {
        title: res.data.message,
      });
    }) 
    .catch ((error) => {
      setMessage(error.response.data.message);
      showAlert('warning', {
        title: error.response.data.message,
      });
    })}
    else{
      showAlert('warning', {
        title:"Please Enter Email.",
      });
    }
  };

  return (
    <div className="login-container">
      <div className='login-content'>
      <h2>Forgot Password</h2>
      <div className="form-group">
          <label htmlFor="username">Email<span>*</span></label>
          <input
            type="text"
            className="form-control"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
          />
        </div>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleForgotPassword}
        >
          Forgot
        </button>
      {/* {message && <p className="message">{message}</p>} */}
    </div>
    </div>
  );
};

export default ForgotPassword;
