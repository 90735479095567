import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate, useLocation } from "react-router-dom";
import "./dash.css";
import Cookies from 'js-cookie';
import axiosInstance from "../../Apis/network2";
const Dashboard2 = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showModalcredentials, setShowModalcredentials] = useState(false);
  const [role, setRole] = useState("");
  const [usertenant, setUserTenant] = useState("")
  const [useroptions, setUseroptions] = useState([])
  const [create_user_group, setCreateusergroup] = useState([])
  const [createuser, SetCreateUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username:"",
    dob:"",
    address:"",
    phoneNo:"",
    tenantId:"",
    user_type:"",
    password: "",
  }) 
const [createAccount, setCreateAccount] = useState({
  "userId": "",
  "accountType": "",
  "tenantId": "",
  "isExternal": "",
  "extBankName": "",
  "extBankAccount": "",
  "extBankAdditionalDetails": "",
  "extBankSortCode": "",
})
const handleChangeNewUser = (e) => {
  const { name, value } = e.target;
  SetCreateUser((prevData) => ({ ...prevData, [name]: value }));
  if(name==="tenantId"){
    var token = Cookies.get('_auth');
        axiosInstance.get('add_groups?tenant_id='+value ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            console.log("data", data)
            setCreateusergroup(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
  }
}
const handleChangeAccount = (e) => {
  const { name, value } = e.target;
  setCreateAccount((prevData) => ({ ...prevData, [name]: value }));
  if(name==="tenantId"){
    var token = Cookies.get('_auth');
        axiosInstance.get('get_user?tenantId='+value ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setUseroptions(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
  }
};
const closeModalNewUser = () => {
  setShowModalUser(false);
};
const openModalNewUser = () => {
  setShowModalUser(true);
};
const handleSubmitNewUser = () => {
  
  if(role==="system"){
    createuser.tenantId = usertenant
  }
  console.log("user", createuser)
  if (createuser.first_name !== "" && createuser.dob !== "" && createuser.email !== "" && createuser.address !== "" && createuser.tenantId !== "" && createuser.user_type !== ""
    && createuser.phoneNo !== ""){
      createuser.username = createuser.email
      if (createuser.user_type !== "basic"){
          if (createuser.password === ""){
            showAlert('warning', {
              title: "Please fill all required fields.",
              timer: 3000,
            });
          }else{
              create_new_user()
            }
      }
      else{
        create_new_user()
      }

  }else{
    showAlert('warning', {
      title: "Please fill all required fields.",
      timer: 3000,
    });
  }

}
const [accesstype, setAccessType] = useState(null)
  // Function to open the modal
  const openAccountModal = () => {
    setShowAccountModal(true);
  };
  const closeAccountModal = () => {
    setShowAccountModal(false);
    setCreateResponse({
      "userId": "",
      "accountType": "",
      "tenantId": "",
      "isExternal": "",
      "extBankName": "",
      "extBankAccount": "",
      "extBankAdditionalDetails": "",
      "extBankSortCode": "",
      
    })
  };
  const openModalcredentials = () => {
    setShowModalcredentials(true);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModalcredentials = () => {
    setShowModalcredentials(false);
    setCreateResponse({
      "username": "",
      "password": "",
      "accountId": "",
    })
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setFormData({
      email: "",
    postCode: "",
    address: "",
    phoneNo: "",
    companyName: "",
    })
  };
    const navigate = useNavigate();
    const showAlert = useSweetAlert();
    const [showDiv, setShowDiv] = useState(false);
  const [tenat, setTenant] = useState([]);
  const [selected_tenant, setSelectedTenant] = useState('');
  const [accounts, setAccounts] = useState([]);

  const handleTenantChange = (event) => {
    setSelectedTenant(event.target.value);
    if (event.target.value !== "") {

    localStorage.setItem("tenant", event.target.value)
    getting_setting_accounts(event.target.value, 1)
}
else{
    setAccounts([])
}
  };
  
  
  function getTenants(){
    var token = Cookies.get('_auth');
        // console.log("token", token)
        // var session = Cookies.get('sessionid');
        // console.log("cookiessss", session)
        // const yourCookie = 'sessionid='+session+';';
        // console.log("cookie", yourCookie)
        axiosInstance.get('/accounts/add_tenant/',{
          headers:{
            'Content-Type': 'application/json',
            // 'Cookie': yourCookie,
        'Authorization': `Bearer ${token}`,
          },
          withCredentials: true 
        },
        )
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setTenant(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
  }
   
  useEffect(() => {
    if (localStorage.getItem("tenant")){
      setSelectedTenant(localStorage.getItem("tenant"));
      getting_setting_accounts(localStorage.getItem("tenant"), 1)
    }
    const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["role"])
    setAccessType(authstate["access"])
    if(authstate["access"] === "all"){
      setRole("superuser")
      
        setShowDiv(true)
        console.log("show div", showDiv)
        getTenants()
    }
    else if(authstate["access"] === "users"){
      setUserTenant(authstate['company'])
      setRole("system")
      setSelectedTenant(authstate['company'])
        getting_setting_accounts(authstate['company'], 1)
        var token = Cookies.get('_auth');
        axiosInstance.get('get_user?tenantId='+ authstate['company'],{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        }).then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setUseroptions(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
        var token = Cookies.get('_auth');
        axiosInstance.get('add_groups?tenant_id='+authstate['company'] ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            console.log("data", data)
            setCreateusergroup(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
    }

  }, []);
  

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Do something before the page unloads (including back button press)
  //     console.log('Before unload dashboard', event);
  //     // if(event.state.usr.account && !event.state.usr.tenant_id){
  //     // navigate("/dash", { state: { account } });
  //     // }
  //     // You can perform additional actions or pass data here

  //     // Optionally, you can provide a message to the user
  //     // const confirmationMessage = 'Are you sure you want to leave?';
  //     // event.returnValue = confirmationMessage; // Standard for most browsers
  //     // window.removeEventListener('beforeunload', handleBeforeUnload);
  //     // return confirmationMessage; // For some older browsers
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('popstate', handleBeforeUnload);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     console.log('Removing listener');
  //   };
  // }, []); 
function create_new_user(){
    var token = Cookies.get('_auth');
        axiosInstance.post('accounts/create/', createuser ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          showAlert('success', {
            title: "User and Account created. Account Number is " + res.data.data.accountId,
            timer: 5000,
          });
          closeModalNewUser()
          SetCreateUser({
            first_name: "",
            last_name: "",
            email: "",
            username:"",
            dob:"",
            address:"",
            phoneNo:"",
            tenantId:"",
            user_type:"",
            password: "",
          });
        }
        else{
            console.log(" in error")
            showAlert('error', {
                title: res.data.message,
                timer: 3000,
              });
              
        }
      })
      .catch((err) => {
        console.error("Error fetching accounts:", err);

        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          
      });
  }


  function getting_setting_accounts(params, page){
    var token = Cookies.get('_auth');
        axiosInstance.get('get_user?page='+ page + "&tenantId=" + params,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.results;
          setAccounts(data);
          setPaginationData({
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
        });
        setCurrentPage(page);
        }
        else{
            console.log(" in error")
            showAlert('error', {
                title: res.data.message,
                timer: 3000,
              });
              setAccounts([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching accounts:", err);

        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          setAccounts([]);
      });
  }
  function enable_account(user_id, tenant_id, bool_active){
    console.log("in enable", user_id, tenant_id, bool_active)
    var params = {
      "userId": user_id,
      "active": bool_active,
    }
    var token = Cookies.get('_auth');
    axiosInstance.post('user_active/', params, {
      headers:{
        'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
      }
    })
.then((res) => {
  if (res.status === 200) {
    let data = res.data.data;
    console.log("res", res)
    showAlert('success', {
      title: res.data.message,
      timer: 5000,
    });
    getting_setting_accounts(tenant_id, currentPage)
  }
  else{
      console.log(" in error")
      showAlert('error', {
          title: res.data.message,
          timer: 3000,
        });
        
  }
})
.catch((err) => {
  console.error("Error fetching accounts:", err);

  showAlert('error', {
      title: err.response.data.message,
      timer: 3000,
    });
    
});
  }
  function view_account(user_id, tenant_id){
    console.log("account_id", user_id)
    navigate("/user_accounts", { state: { user_id, tenant_id, currentPage } });
  }
  const [formData, setFormData] = useState({
    email: "",
    postCode: "",
    address: "",
    phoneNo: "",
    companyName: "",
    username: "",
  });
const [createResponse, setCreateResponse] = useState({
  "username": "",
  "password": "",
  "accountId": "",
})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  function AccountCreation(){
    var token = Cookies.get('_auth');
    if(createAccount.userId !== "" && createAccount.accountType !== "" && createAccount.tenantId!== ""){
        axiosInstance.post('accounts/create_account_system/', createAccount, {
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        setCreateAccount({
          email: "",
        postCode: "",
        address: "",
        phoneNo: "",
        companyName: "",
        })

        closeAccountModal();
        showAlert('success', {
          title: "New account number is " + res.data.data.accountId,
          timer: 5000,
        });
      }
      else{
          console.log(" in error")
          showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
            
      }
    })
    .catch((err) => {
      console.error("Error fetching accounts:", err);

      showAlert('error', {
          title: err.response.data.message,
          timer: 3000,
        });
        
    });
  }
  else{
    showAlert('error', {
      title: "Please fill all fields.",
      timer: 3000,
    });
  }

  }
  const handleSubmitAccount = () => {
    if (createAccount.accountType && createAccount.userId){
      if (createAccount.accountType === "external"){
        createAccount.isExternal = 1
        if(createAccount.extBankAccount === "" && createAccount.extBankName=== "" && createAccount.extBankSortCode === ""){
          showAlert('error', {
            title: "Fill all required fileds",
            timer: 3000,
          });
        
        }
        else{
          AccountCreation()
        }

      }
      else{
        createAccount.isExternal = 0
        AccountCreation()
      }

    }else{
      showAlert('error', {
        title: "Fill all required fileds",
        timer: 3000,
      });
    }
    console.log("Account create", createAccount)
  }
  const handleSubmit = () => {
    console.log("formData", formData)
    if (formData.email !== "" && formData.postCode !== "" && formData.address !== "" && formData.phoneNo !== "" && formData.companyName !== "" ){
      formData.username = formData.email
      var token = Cookies.get('_auth');
        axiosInstance.post('/accounts/add_tenant/', formData, {
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setCreateResponse(data);
          setFormData({
            email: "",
          postCode: "",
          address: "",
          phoneNo: "",
          companyName: "",
          })
          closeModal();
          openModalcredentials()
          getTenants()
          
          
        }
        else{
            console.log(" in error")
            showAlert('error', {
                title: res.data.message,
                timer: 3000,
              });
              setCreateResponse({
                "username": "",
                "password": "",
                "accountId": "",
              });
        }
      })
      .catch((err) => {
        console.error("Error fetching accounts:", err);

        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          setCreateResponse({
            "username": "",
            "password": "",
            "accountId": "",
          });
      });
      
    }
    else{
      showAlert('warning', {
        title: "All fields are required.",
        timer: 3000,
      });
    }
    
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    count: 0,
    next: null,
    previous: null
});
  const goToNextPage = () => {
    console.log("pagination data", paginationData)
    if (paginationData.next) {
      console.log("pagination data", paginationData)
        const nextPage = currentPage + 1;
        getting_setting_accounts(selected_tenant, nextPage);
    }
};

const goToPreviousPage = () => {
    if (paginationData.previous) {
        const prevPage = currentPage - 1;
        getting_setting_accounts(selected_tenant, prevPage);
    }
};
const [group_modal, setGroup_modal] = useState(false)
const [permission_options, setPermission_options] = useState([])
const [create_group, setCreate_group] = useState({
  "user_permissions": [],
  "group_name": "",
  "rank": "0",
  "tenant_id": "", 
})

const handleChangeGroup = (e) => {
  const { name, value } = e.target;
  console.log("name, value", name, value)
  if (name === "user_permissions") {
    // Check if the permission with the same ID is already present in the array
    const isAlreadyAdded = create_group.user_permissions.some(permission => permission.id === parseInt(value));
  
    // If the permission is not already added, add it to the array
    if (!isAlreadyAdded) {
      // Find the selected object from permission_options array using its ID
      const selectedPermission = permission_options.find(permission => permission.id === parseInt(value));
      console.log("search data", selectedPermission);
  
      // Update the state by appending the selected permission to the existing array
      setCreate_group(prevState => ({
        ...prevState,
        user_permissions: [...prevState.user_permissions, selectedPermission]
      }));
    }
  }
  else{
  setCreate_group((prevData) => ({ ...prevData, [name]: value }));
}
}

const openGroupModal = () => {
  setGroup_modal(true)
  var token = Cookies.get('_auth');
        axiosInstance.get('add_permission/' ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          console.log("data1122", data)
          
          setPermission_options(res.data.data)
        }
        else{
            console.log(" in error")
            
              
        }
      })
      .catch((err) => {
        console.error("Error fetching accounts:", err);

        
          
      });

}
const closeGroupModal = () => {
  setGroup_modal(false)
}
const handleSubmitGroup = () => {
  console.log("create gourp", create_group)
  if (create_group.rank !== "0" && create_group.rank !== "" && create_group.name !== "" && (create_group.tenant_id !== "" || accesstype !== "all" ) && create_group.user_permissions.length > 0){
    if (create_group.tenant_id === ""){
      create_group.tenant_id = usertenant
    }
      const userPermissionIds = create_group.user_permissions.map(permission => permission.id);
const commaSeparatedIds = userPermissionIds.join(',');
      var form_group = {
        "user_permissions": commaSeparatedIds,
          "group_name": create_group.group_name,
          "rank": create_group.rank,
          "tenant_id": create_group.tenant_id, 
      }
      var token = Cookies.get('_auth');
      axiosInstance.post('add_groups/' , form_group, {
        headers:{
          'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
        }
      })
    .then((res) => {
      if (res.status === 200) {
        console.log("res", res)
        showAlert('success', {
          title: res.data.message,
          timer: 3000,
        });
        axiosInstance.get('add_groups?tenant_id='+create_group.tenant_id ,{
          headers:{
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            console.log("data", data)
            setCreateusergroup(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
        setCreate_group({
          "user_permissions": [],
          "group_name": "",
          "rank": "0",
          "tenant_id": "", 
        })
        closeGroupModal()
        
        
        // let data = res.data.data;
        // console.log("data1122", data)
        
        // setPermission_options(res.data.data)
      }
      else{
          console.log(" in error")
          showAlert('error', {
            title: res.data.message,
            timer: 3000,
          });
          
            
      }
    })
    .catch((err) => {
      console.error("Error fetching accounts:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });

      
        
    });

  }
  else{
    showAlert('error', {
      title: "All fields are required.",
      timer: 3000,
    });
  }
}
const handleRemovePermission = (id) => {
  const updatedPermissions = create_group.user_permissions.filter(permission => permission.id !== parseInt(id));

  // Update the state with the filtered permissions
  setCreate_group(prevState => ({
    ...prevState,
    user_permissions: updatedPermissions
  }));
}
  return (
    <>
    
      <Layout />
      <div className="container" style={{ marginTop: "80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        {showDiv && (<div className="form-field mb-3" id="choose_tenant" >
          <label htmlFor="fileInput" className="form-label"><h2>Choose Tenant</h2></label>
          <div className="input-group">
            <select
              className="form-control form-control-sm"
              id="fileInput"
              style={{ margin: "auto" }}
              onChange={handleTenantChange}
              value={selected_tenant}
            >
              <option value="">Select an option</option>
              {tenat.map((tenant) => (
                <option key={tenant.id} value={tenant.companyName}>
                  {tenant.companyName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-field mb-3 mt-3">
          <button
  className="btn btn-success"
  onClick={openModal}
  // data-bs-toggle="modal"
  // data-bs-target="#addTenantModal"
>
  Add New Tenant
</button>
</div>
        </div>)}
       
        <div>
          <div className="row">
            <div className="col-6">
          <h2>Users</h2>
          </div>
          <div className="col-6 ">
          <button 
  className="btn btn-success"
  onClick={openModalNewUser}
  style={{margin:"10px"}}
  // data-bs-toggle="modal"
  // data-bs-target="#addTenantModal"
>
  Add New User
</button>
          <button
  className="btn btn-success"
  onClick={openAccountModal}
  style={{margin:"10px"}}
  // data-bs-toggle="modal"
  // data-bs-target="#addTenantModal"
>
  Add New Account
</button>
<button
  className="btn btn-success"
  onClick={openGroupModal}
  style={{margin:"10px"}}
  // data-bs-toggle="modal"
  // data-bs-target="#addTenantModal"
>
  Add User Group
</button>
</div>
</div>
          <table className="table w-100">
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role </th>
                <th>Email</th>
                <th>Active</th>
                
                <th>Phone No</th>
                <th>Tenant ID</th>
                <th>Enable/Disable</th>
                <th>Action</th>
                
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => (
                <tr key={account.id}>
                  <td className="text-center">{account.id}</td>
                  <td className="text-center">{account.username}</td>
                  <td className="text-center">{account.first_name}</td>
                  <td className="text-center">{account.last_name}</td>
                  <td className="text-center">{account.groups}</td>
                  <td className="text-center">{account.email}</td>
                  <td className="text-center">{account.is_active ? "Yes" : "No"}</td>
                  <td>{account.phoneNo}</td>
                  <td>{account.tenantId}</td>
                  <td><button className="btn btn-success" onClick={() => enable_account(account.id, account.tenantId, !account.is_active)}>{account.is_active ? 'Disable' : 'Enable'}</button></td>
                  <td> <button className="btn btn-success" onClick={() => view_account(account.id, account.tenantId)}>View</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          {accounts.length !== 0 && (<div className="pagination-controls">
            <button className="btn btn-success" onClick={goToPreviousPage} disabled={!paginationData.previous}>Previous</button>
            <span style={{margin: "5px"}}>{currentPage}</span>
            <button className="btn btn-success" onClick={goToNextPage} disabled={!paginationData.next}>Next</button>
        </div>)}
        </div>
      </div>
      {/* <NewTenantModal showModal={showModal} closeModal={closeModal} /> */}
      {showModal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New Tenant
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Company Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                maxlength="200"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxlength="50"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Phone No:
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNo"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Address:
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                maxlength="1000"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Post Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="postCode"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={formData.email}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
              
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Create Tenant
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    {showModalUser && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModalUser}
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New User
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalNewUser}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            {accesstype === 'all' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={createuser.tenantId}
              onChange={handleChangeNewUser}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              User Type*:
            </label>
            <select
              className="form-control"
              id="user_type"
              name="user_type"
              value={createuser.user_type}
              onChange={handleChangeNewUser}
            >
              <option value="">Select User Type</option>
              {create_user_group.map((user) => (
              create_user_group.length > 0 && (
                <option key={user.id} value={user.group_name}>
                  {user.group_name}
                </option>
              )
            ))}
            </select>
          
            </div>
            <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        First Name*:
      </label>
      <input
        type="text"
        className="form-control"
        id="first_name"
        name="first_name"
        value={createuser.first_name}
        onChange={handleChangeNewUser}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Last Name:
      </label>
      <input
        type="text"
        className="form-control"
        id="last_name"
        name="last_name"
        value={createuser.last_name}
        onChange={handleChangeNewUser}
        maxLength="20" 
      />
    </div>
    
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Email*:
      </label>
      <input
        type="text"
        className="form-control"
        id="email"
        name="email"
        value={createuser.email}
        onChange={handleChangeNewUser}
        maxLength="40" 
      />
    </div>
    <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={createuser.email}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
              
            </div>
    {createuser.user_type !== 'basic' && (<div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Password*:
      </label>
      <input
        type="text"
        className="form-control"
        id="password"
        name="password"
        value={createuser.password}
        onChange={handleChangeNewUser}
        maxLength="20" 
      />
    </div>)}
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Date of birth*:
      </label>
      <input
        type="date"
        className="form-control"
        id="dob"
        name="dob"
        value={createuser.dob}
        onChange={handleChangeNewUser}
        // maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Address*:
      </label>
      <input
        type="text"
        className="form-control"
        id="address"
        name="address"
        value={createuser.address}
        onChange={handleChangeNewUser}
        maxLength="500" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        Phone No*:
      </label>
      <input
        type="text"
        className="form-control"
        id="phoneNo"
        name="phoneNo"
        value={createuser.phoneNo}
        onChange={handleChangeNewUser}
        maxLength="20" 
      />
    </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitNewUser}
            >
              Create User
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModalNewUser}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    {showAccountModal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showAccountModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New Account
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeAccountModal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            {role === 'superuser' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenantId"
              name="tenantId"
              value={createAccount.tenantId}
              onChange={handleChangeAccount}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              User*:
            </label>
            <select
              className="form-control"
              id="userId"
              name="userId"
              value={createAccount.userId}
              onChange={handleChangeAccount}
              disabled={createAccount.role === 'superuser' || useroptions.length === 0}
            >
              <option value="">Select a user</option>
              {useroptions.map((user) => (
              useroptions.length > 0 && (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              )
            ))}
            </select>
          </div>
            <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Account Type*:
            </label>
            <select
              className="form-control"
              id="accountType"
              name="accountType"
              value={createAccount.accountType}
              onChange={handleChangeAccount}
            >
              <option value="">Select account type</option>
              <option value="normal">Normal</option>
              <option value="system">System</option>
              <option value="external">External</option>
            </select>
          
            </div>
            
            {createAccount.accountType === "external" && (
  <div>
    <div className="mb-3">
      <label htmlFor="extBankName" className="form-label">
        External Bank Name*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankName"
        name="extBankName"
        value={createAccount.extBankName}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankAccount" className="form-label">
        External Bank Account*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankAccount"
        name="extBankAccount"
        value={createAccount.extBankAccount}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankAdditionalDetails" className="form-label">
        External Bank Additional Details:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankAdditionalDetails"
        name="extBankAdditionalDetails"
        value={createAccount.extBankAdditionalDetails}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
    <div className="mb-3">
      <label htmlFor="extBankSortCode" className="form-label">
        External Bank Sort Code*:
      </label>
      <input
        type="text"
        className="form-control"
        id="extBankSortCode"
        name="extBankSortCode"
        value={createAccount.extBankSortCode}
        onChange={handleChangeAccount}
        maxLength="20" 
      />
    </div>
  </div>
)}
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitAccount}
            >
              Create Account
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeAccountModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    { showModalcredentials && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Credentials for system user
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalcredentials}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={createResponse.username}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Password:
              </label>
              <input
                type="email"
                className="form-control"
                id="password"
                name="password"
                value={createResponse.password}
                maxlength="20"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Account Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="accountId"
                name="accountId"
                value={createResponse.accountId}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModalcredentials}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    { group_modal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New User Group
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeGroupModal}
            ></button>
          </div>
          <div className="modal-body">
          {accesstype === 'all' && ( <div className="mb-3">
            <label htmlFor="accountType" className="form-label">
              Tenant*:
            </label>
            <select
              className="form-control"
              id="tenant_id"
              name="tenant_id"
              value={create_group.tenant_id}
              onChange={handleChangeGroup}
            >
              <option value="">Select a tenant</option>
              {tenat.map((user) => (
              tenat.length > 0 && (
                <option key={user.id} value={user.companyName}>
                  {user.companyName}
                </option>
              )
            ))}
            </select>
          </div>)}
            {/* Form fields for new tenant */}
            <div className="mb-3">
            <label htmlFor="user_permissions" className="form-label">
              Permissions*:
            </label>
            <select
              className="form-control form-control-sm"
              id="user_permissions"
              name="user_permissions"
              style={{ margin: "auto" }}
              onChange={handleChangeGroup}
              value={create_group.user_permissions}
              
            >
              <option value="">Select permissions</option>
              {permission_options.map((tenant) => (
                <option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </option>
              ))}
            </select>
          </div>
          {create_group.user_permissions.length > 0 && (
  <div>
    {create_group.user_permissions.map((selectedPermission) => (
      <div key={selectedPermission.id}>
        <span>{selectedPermission.name}</span>
        <button  onClick={() => handleRemovePermission(selectedPermission.id)}>x</button>
      </div>
    ))}
  </div>
)}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Rank:
              </label>
              <input
                type="number"
                className="form-control"
                id="rank"
                name="rank"
                value={create_group.rank}
                maxlength="20"
                onChange={handleChangeGroup}

              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="group_name"
                name="group_name"
                value={create_group.group_name}
                onChange={handleChangeGroup}
                maxlength="20"
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitGroup}
            >
              Create Group
            </button>
            
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeGroupModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    </>
  );
};

export default Dashboard2;
