import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_tenants } from "../../Apis/network";
import { get_accounts } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate } from "react-router-dom";
// import "./dash.css";
import Cookies from 'js-cookie';
import { get_users } from "../../Apis/network";
import { add_new_tenant } from "../../Apis/network";
import { refreshToken } from "../../Apis/network";
import { add_new_account } from "../../Apis/network";
import { add_new_user_account } from "../../Apis/network";
const CreateUserWidget = () => {
    const showAlert = useSweetAlert();
    const [role, setRole] = useState("");
    const [tenat, setTenant] = useState([]);
    const [usertenant, setUserTenant] = useState("")
    const [createuser, SetCreateUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        username:"",
        dob:"",
        address:"",
        phoneNo:"",
        tenantId:"",
        user_type:"",
        password: "",
      }) 
const handleChangeNewUser = (e) => {
const { name, value } = e.target;
SetCreateUser((prevData) => ({ ...prevData, [name]: value }));
}
const handleSubmitNewUser = () => {
  
    if(role==="system"){
      createuser.tenantId = usertenant
    }
    
    console.log("user", createuser)
    
    if (createuser.first_name !== "" && createuser.dob !== "" && createuser.email !== "" && createuser.address !== "" && createuser.tenantId !== "" && createuser.user_type !== ""
      && createuser.phoneNo !== ""){
        createuser.username = createuser.email
        if (createuser.user_type !== "basic"){
            if (createuser.password === ""){
              showAlert('warning', {
                title: "Please fill all required fields.",
                timer: 3000,
              });
            }else{
                create_new_user()
              }
        }
        else{
          create_new_user()
        }
  
    }else{
        if (!role){
            showAlert('warning', {
                title: "Please Login.",
                timer: 3000,
              });
        }
        else{
      showAlert('warning', {
        title: "Please fill all required fields.",
        timer: 3000,
      });}
    }
  
  }
  function create_new_user(){
    add_new_user_account(createuser)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          showAlert('success', {
            title: "User and Account created. Account Number is " + res.data.data.accountId,
            timer: 5000,
          });
        //   closeModalNewUser()
          SetCreateUser({
            first_name: "",
            last_name: "",
            email: "",
            username:"",
            dob:"",
            address:"",
            phoneNo:"",
            tenantId:"",
            user_type:"",
            password: "",
          });
        }
        else{
            console.log(" in error")
            showAlert('error', {
                title: res.data.message,
                timer: 3000,
              });
              
        }
      })
      .catch((err) => {
        console.error("Error fetching accounts:", err);

        showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
          ;
      });
  }
  useEffect(() => {
    
    const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["role"])
    if(authstate["role"] === "superuser"){
      setRole("superuser")
      
        
        get_tenants()
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setTenant(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching tenants:", err);
      });
    }
    else if(authstate["role"] === "system"){
      setUserTenant(authstate['company'])
      setRole("system")
        // getting_setting_accounts(authstate['company'])
       
    }

  }, []);
return (
    <>
<div
    className={`modal show`}
    style={{display:"block"}}
    tabIndex="-1"
    role="dialog"
    aria-labelledby="addTenantModalLabel"
    // aria-hidden={!showModalUser}
  >
    <div className="modal-dialog modal-dialog-scrollable" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addTenantModalLabel">
            Add New User
          </h5>
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModalNewUser}
          ></button> */}
        </div>
        <div className="modal-body">
          {/* Form fields for new tenant */}
          {role === 'superuser' && ( <div className="mb-3">
          <label htmlFor="accountType" className="form-label">
            Tenant*:
          </label>
          <select
            className="form-control"
            id="tenantId"
            name="tenantId"
            value={createuser.tenantId}
            onChange={handleChangeNewUser}
          >
            <option value="">Select a tenant</option>
            {tenat.map((user) => (
            tenat.length > 0 && (
              <option key={user.id} value={user.companyName}>
                {user.companyName}
              </option>
            )
          ))}
          </select>
        </div>)}
          
          <div className="mb-3">
          <label htmlFor="accountType" className="form-label">
            User Type*:
          </label>
          <select
            className="form-control"
            id="user_type"
            name="user_type"
            value={createuser.user_type}
            onChange={handleChangeNewUser}
          >
            <option value="">Select user type</option>
            <option value="basic">basic</option>
            <option value="system">System</option>
          </select>
        
          </div>
          <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      First Name*:
    </label>
    <input
      type="text"
      className="form-control"
      id="first_name"
      name="first_name"
      value={createuser.first_name}
      onChange={handleChangeNewUser}
      maxLength="20" 
    />
  </div>
  <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Last Name:
    </label>
    <input
      type="text"
      className="form-control"
      id="last_name"
      name="last_name"
      value={createuser.last_name}
      onChange={handleChangeNewUser}
      maxLength="20" 
    />
  </div>
  
  <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Email*:
    </label>
    <input
      type="text"
      className="form-control"
      id="email"
      name="email"
      value={createuser.email}
      onChange={handleChangeNewUser}
      maxLength="40" 
    />
  </div>
  <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Username:
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={createuser.email}
              onChange={handleChangeNewUser}
              maxlength="20"
              disabled
            />
            
          </div>
  {createuser.user_type === 'system' && (<div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Password*:
    </label>
    <input
      type="text"
      className="form-control"
      id="password"
      name="password"
      value={createuser.password}
      onChange={handleChangeNewUser}
      maxLength="20" 
    />
  </div>)}
  <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Date of birth*:
    </label>
    <input
      type="date"
      className="form-control"
      id="dob"
      name="dob"
      value={createuser.dob}
      onChange={handleChangeNewUser}
      // maxLength="20" 
    />
  </div>
  <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Address*:
    </label>
    <input
      type="text"
      className="form-control"
      id="address"
      name="address"
      value={createuser.address}
      onChange={handleChangeNewUser}
      maxLength="500" 
    />
  </div>
  <div className="mb-3">
    <label htmlFor="extBankName" className="form-label">
      Phone No*:
    </label>
    <input
      type="text"
      className="form-control"
      id="phoneNo"
      name="phoneNo"
      value={createuser.phoneNo}
      onChange={handleChangeNewUser}
      maxLength="20" 
    />
  </div>
          
          {/* Add similar fields for postCode, address, phoneNo, and companyName */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmitNewUser}
          >
            Create User
          </button>
          {/* <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={closeModalNewUser}
          >
            Close
          </button> */}
        </div>
      </div>
    </div>
  </div>
   </>
   );
 };
 
 export default CreateUserWidget;