// NewTenantModal.js
import React, { useState } from "react";
import { add_new_tenant } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";



const NewTenantModal = () => {
    const [showModalcredentials, setShowModalcredentials] = useState(false);
    const showAlert = useSweetAlert();
    const openModalcredentials = () => {
        setShowModalcredentials(true);
      };
      const closeModalcredentials = () => {
        setShowModalcredentials(false);
        setCreateResponse({
          "username": "",
          "password": "",
          "accountId": "",
        })
      };
    const [formData, setFormData] = useState({
        email: "",
        postCode: "",
        address: "",
        phoneNo: "",
        companyName: "",
        username: ""
      });
    const [createResponse, setCreateResponse] = useState({
      "username": "",
      "password": "",
      "accountId": "",
    })
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleSubmit = () => {
        console.log("formData", formData)
        if (formData.email !== "" && formData.postCode !== "" && formData.address !== "" && formData.phoneNo !== "" && formData.companyName !== "" ){
            formData.username = formData.email
          add_new_tenant(formData)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              setCreateResponse(data);
              openModalcredentials()
              
            }
            else{
                console.log(" in error")
                showAlert('error', {
                    title: res.data.message,
                    timer: 3000,
                  });
                  setCreateResponse({
                    "username": "",
                    "password": "",
                    "accountId": "",
                  });
            }
          })
          .catch((err) => {
            console.error("Error fetching accounts:", err);
    
            showAlert('error', {
                title: err.response.data.message,
                timer: 3000,
              });
              setCreateResponse({
                "username": "",
                "password": "",
                "accountId": "",
              });
          });
          setFormData({
            email: "",
          postCode: "",
          address: "",
          phoneNo: "",
          companyName: "",
          })
          
        }
        else{
          showAlert('warning', {
            title: "All fields are required.",
            timer: 3000,
          });
        }
        
      };
  

  return (
    <>
    <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
    //   aria-hidden={}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New Tenant
            </h5>
           
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Company Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                maxlength="200"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Phone No:
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNo"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Address:
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                maxlength="1000"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Post Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="postCode"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Create Tenant
            </button>
            
          </div>
        </div>
      </div>
    </div>
    { showModalcredentials && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
    //   aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Credentials for system user
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalcredentials}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={createResponse.username}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Password:
              </label>
              <input
                type="email"
                className="form-control"
                id="password"
                name="password"
                value={createResponse.password}
                maxlength="20"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Account Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="accountId"
                name="accountId"
                value={createResponse.accountId}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={formData.email}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
              
            </div>
          <div className="modal-footer">
            
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModalcredentials}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}

    </>
  );
};

export default NewTenantModal;
