import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { get_account_details, get_account_transaction } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import "./account_details.css";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Apis/network2";

const UserAccounts = () => {
  const showAlert = useSweetAlert();
  const location = useLocation();
  const navigate = useNavigate();

  const [account, setAccount] = useState({
    "user_id": -1,
    "tenant_id": "",
    "currentPage": -1,
  });

  const [accountDetails, setAccountDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);


  function getting_setting_accounts(page) {
    var token = Cookies.get('_auth');
    axiosInstance.get('accounts/get_accounts_detail?user_id=' + account.user_id + "&page=" + page, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
        console.log("res", res)
        if (res.status === 200) {
          let data = res.data.results;
          setAccountDetails(data);
          setPaginationData({
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
          });
          setCurrentPage(page);
        } else {
          showAlert('error', {
            title: res.data.message,
            timer: 3000,
          });
          setAccountDetails([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching account details:", err);
        showAlert('error', {
          title: err.response.data.message,
          timer: 3000,
        });
        setAccountDetails([]);
      });
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    console.log(" b component", location.state)
    if (location.state) {
      setAccount(location.state);
      const cookieValue = Cookies.get('_auth_state');
      console.log("b account", account)
      if (!cookieValue) {
        navigate("/")
      }
      if (location.state.user_id !== -1) {
        var token = Cookies.get('_auth');
        axiosInstance.get('accounts/get_accounts_detail?user_id=' + location.state.user_id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        })
          .then((res) => {
            console.log("res", res)
            if (res.status === 200) {
              let data = res.data.results;
              setAccountDetails(data);
              setPaginationData({
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous
              });
            } else {
              showAlert('error', {
                title: res.data.message,
                timer: 3000,
              });
              setAccountDetails([]);
            }
          })
          .catch((err) => {
            console.error("Error fetching account details:", err);
            showAlert('error', {
              title: err.response.data.message,
              timer: 3000,
            });
            setAccountDetails([]);
            });
          }

    }
    else {
      console.log("in else location not found")
      const cookieValue = Cookies.get('_auth_state');
      if (cookieValue) {
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
        var params = {
          "user_id": authstate["id"],
          "tenant_id": authstate["company"],
          "page": 1
        }
        console.log("params", params)
        setAccount(params)
        if (params.user_id !== -1) {
          var token = Cookies.get('_auth');
          axiosInstance.get('accounts/get_accounts_detail?user_id=' + params.user_id, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          })
            .then((res) => {
              console.log("res", res)
              if (res.status === 200) {
                let data = res.data.results;
                setAccountDetails(data);
                setPaginationData({
                  count: res.data.count,
                  next: res.data.next,
                  previous: res.data.previous
                });
              } else {
                showAlert('error', {
                  title: res.data.message,
                  timer: 3000,
                });
                setAccountDetails([]);
              }
            })
            .catch((err) => {
              console.error("Error fetching account details:", err);
              showAlert('error', {
                title: err.response.data.message,
                timer: 3000,
              });
              setAccountDetails([]);
            });
      }
    }
      else {
        navigate("/")
      }



      
    }
    
    console.log("checking account", account)
    
    
    



    

  }, [location.state]);
  const [initialLoad, setInitialLoad] = useState(true);
  // useEffect(() => {

  //   console.log('Back button pressed b hello', );
  //   const handlePopstate = (event) => {
  //     // Check if the popstate event was triggered by a back button press
  //     console.log('Back button pressed b', event.state);
  //     if (event.state === null) {
  //       console.log('Back button pressed b', event.state);

  //       // Check if event.state.usr is defined before accessing account property
  //       if (!event.state.usr) {
  //         console.log("in condition")
  //         // const { account } = event.state.usr;
  //         navigate("/dash", { state: { account } });
  //       }
  //     } else {
  //       // The popstate event was triggered by a forward or regular navigation
  //       console.log('Regular navigation');
  //     }

  //     // Optionally, provide a message to the user
  //     const confirmationMessage = 'Are you sure you want to leave?';
  //     event.returnValue = confirmationMessage; // Standard for most browsers
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('popstate', handlePopstate);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //     console.log('Removing listener');
  //   };
  // }, [initialLoad, navigate, account]);
  // window.onpopstate = (event) => {
  //   //   navigate("/user_account", { state: { account }});
  //   console.log('Back button pressed b', event.state);
  //   if (event.state === null) {
  //     console.log('Back button pressed b', event.state);

  //     // Check if event.state.usr is defined before accessing account property
  //     if (!event.state.usr) {
  //       console.log("in condition")
  //       // const { account } = event.state.usr;
  //       navigate("/dash", { state: { account } });
  //     }
  //   } else {
  //     // The popstate event was triggered by a forward or regular navigation
  //     console.log('Regular navigation');
  //   }
  //   }
  const [showModaltransfer, setShowModalTransfer] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    count: 0,
    next: null,
    previous: null
  });
  const goToNextPage = () => {
    console.log("pagination data", paginationData)
    if (paginationData.next) {
      console.log("pagination data", paginationData)
      const nextPage = currentPage + 1;
      getting_setting_accounts(nextPage)
    }
  };
  const goToPreviousPage = () => {
    if (paginationData.previous) {
      const prevPage = currentPage - 1;
      getting_setting_accounts(prevPage)

    }
  };
  const closeModalcredentials = () => {
    setShowModalTransfer(false)
  }
  const closeModaltitle = () => {
    setOpenmodalwithtitle(false)
  }
  const openModal = () => {
    setShowModalTransfer(true)
  }
  const [sendtoaccount, setSendtoaccount] = useState("")
  const [openmodalwithtitle, setOpenmodalwithtitle] = useState(false)

  const [gettitleresults, setGettitleresults] = useState({
    "fromAccount": 0,
    "id": 0,
    "tenantId": "",
    "userId": "",
    "accountType": "",
    "amount": "",
    "description": ""
  })
  const setvalue = (e) => {
    setSendtoaccount(e.target.value)
  }
  const handleChangeResults = (e) => {
    console.log("in change")
    const { name, value } = e.target;
    setGettitleresults((prevData) => ({ ...prevData, [name]: value }));
  }
  const handleSubmitTransaction = () => {

    if (gettitleresults.hasOwnProperty("fromAccount") && gettitleresults.hasOwnProperty("amount") && gettitleresults.hasOwnProperty("description") && gettitleresults.fromAccount !== "" && gettitleresults.amount !== "" && gettitleresults.description !== "") {
      console.log("results", gettitleresults)
      var data = {
        "fromAccount": gettitleresults.fromAccount,
        "toAccount": gettitleresults.id,
        "transactionAmount": gettitleresults.amount,
        "description": gettitleresults.description
      }
      var token = Cookies.get('_auth');
      axiosInstance.post('accounts/user_transaction/', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          console.log("res", res)
          if (res.status === 200) {
            setOpenmodalwithtitle(false)
            setGettitleresults({
              "fromAccount": 0,
              "id": 0,
              "tenantId": "",
              "userId": "",
              "accountType": "",
              "amount": "",
              "description": ""
            })
            showAlert('success', {
              title: res.data.message,
              timer: 3000,
            });
          } else {
            showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.error("Error fetching account details:", err);
          showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
        });

    }
    else {
      showAlert('error', {
        title: "Please fill all fields.",
        timer: 3000,
      });
    }
  }
  const getTitle = () => {
    if (sendtoaccount === "") {
      showAlert('error', {
        title: "Please fill Account number.",
        timer: 3000,
      });
    }
    else {
      console.log("account number =", sendtoaccount)
      var token = Cookies.get('_auth');
      axiosInstance.get('accounts/get_title?account_id=' + sendtoaccount, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          console.log("res", res)
          if (res.status === 200) {
            setGettitleresults(res.data.data)
            setShowModalTransfer(false)
            setSendtoaccount("")
            setOpenmodalwithtitle(true)
          } else {
            showAlert('error', {
              title: res.data.message,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.error("Error fetching account details:", err);
          showAlert('error', {
            title: err.response.data.message,
            timer: 3000,
          });
        });
    }
  }
  function view_account(account_id, tenant_id) {
    console.log("account_id", account_id)
    navigate("/account_details", { state: { account_id, tenant_id, account } });
  }
  function enable_account(user_id, tenant_id, bool_active){
    console.log("in enable", user_id, tenant_id, bool_active)
    var params = {
      "accountId": user_id,
      "active": bool_active,
    }
    var token = Cookies.get('_auth');
    axiosInstance.post('accounts/account_active/', params, {
      headers:{
        'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
      }
    })
.then((res) => {
  if (res.status === 200) {
    let data = res.data.data;
    console.log("res", res)
    showAlert('success', {
      title: res.data.message,
      timer: 5000,
    });
    getting_setting_accounts(currentPage)
  }
  else{
      console.log(" in error")
      showAlert('error', {
          title: res.data.message,
          timer: 3000,
        });
        
  }
})
.catch((err) => {
  console.error("Error fetching accounts:", err);

  showAlert('error', {
      title: err.response.data.message,
      timer: 3000,
    });
    
});
  }
  return (
    <>
      <Layout />
      <div className="container" style={{ marginTop: "80px" }}>


        {/* Display Account Details */}
        <div>
          <div className="row">
            <div className="col-6"><h2>Accounts</h2></div>
            <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}><button className="btn btn-success" onClick={openModal}>Transfer Money</button></div>
          </div>
          <table className="table" style={{ marginTop: "5px" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Current Balance</th>
                <th>Pending Balance (Debit)</th>
                <th>Pending Balance (Credit)</th>
                <th>Account Type</th>
                <th>Active</th>


                <th>Tenant ID</th>
                <th>Enable/Disable</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {accountDetails.map((account) => (
                <tr key={account.id}>
                  <td className="text-center">{account.id}</td>
                  <td className="text-center">{account.userId}</td>
                  <td className="text-center">{account.currentBalance}</td>
                  <td className="text-center">{account.pendingBalanceDebit}</td>
                  <td className="text-center">{account.pendingBalanceCredit}</td>
                  <td className="text-center">{account.accountType}</td>
                  <td className="text-center">{account.isActive ? "Yes" : "No"}</td>

                  <td>{account.tenantId}</td>
                  <td><button className="btn btn-success" onClick={() => enable_account(account.id, account.tenantId, !account.isActive)}>{account.isActive ? 'Disable' : 'Enable'}</button></td>
                  <td> <button className="btn btn-success" onClick={() => view_account(account.id, account.tenantId)}>View</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          {accountDetails.length !== 0 && (<div className="pagination-controls">
            <button className="btn btn-success" onClick={goToPreviousPage} disabled={!paginationData.previous}>Previous</button>
            <span style={{ margin: "5px" }}>{currentPage}</span>
            <button className="btn btn-success" onClick={goToNextPage} disabled={!paginationData.next}>Next</button>
          </div>)}
        </div>
        {showModaltransfer && (
          <div
            className={`modal show`}
            style={{ display: "block" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addTenantModalLabel"
            aria-hidden={!showModaltransfer}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addTenantModalLabel">
                    Credentials for system user
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModalcredentials}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* Form fields for new tenant */}
                  {/* <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={createResponse.username}
                maxlength="200"
                disabled
              />
            </div> */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Account Number:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountId"
                      name="accountId"
                      value={sendtoaccount}
                      onChange={setvalue}
                      maxlength="20"

                    />
                  </div>

                  {/* Add similar fields for postCode, address, phoneNo, and companyName */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={getTitle}
                  >
                    Get Title
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeModalcredentials}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>)}

        {openmodalwithtitle && (
          <div
            className={`modal show`}
            style={{ display: "block" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addTenantModalLabel"
            aria-hidden={!showModaltransfer}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addTenantModalLabel">
                    Transfer Money
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModaltitle}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* Form fields for new tenant */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Send From:
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control form-control-sm"
                        id="fromAccount"
                        name="fromAccount"
                        style={{ margin: "auto" }}
                        onChange={handleChangeResults}
                        value={gettitleresults.fromAccount}
                        required
                      >
                        <option value="">Select an option</option>
                        {accountDetails.map((tenant) => (
                          <option key={tenant.id} value={tenant.id}>
                            {tenant.id}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Send To:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="id"
                      name="id"
                      value={gettitleresults.id}
                      maxLength="200"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Username:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userId"
                      name="userId"
                      value={gettitleresults.userId}
                      maxLength="200"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Tenent:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tenantId"
                      name="tenantId"
                      value={gettitleresults.tenantId}
                      maxLength="20"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Description:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                      value={gettitleresults.description}
                      onChange={handleChangeResults}
                      maxLength="20"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Amount:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="amount"
                      name="amount"
                      value={gettitleresults.amount}
                      onChange={handleChangeResults}
                      maxLength="20"
                      required
                    />
                  </div>

                  {/* Add similar fields for postCode, address, phoneNo, and companyName */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmitTransaction}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={closeModaltitle}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>)}







      </div>
    </>
  );
};

export default UserAccounts;
